import React from "react";
import { IGrid } from "../../../types/uiComponentTypes";
import cn from "classnames";
import { PageGenerator } from "../../../app/uiFunctions/PageGenerator";
import classGenerator from "../../../app/uiFunctions/classGenerator";

export const Grid: IGrid = ({
  gap = 4,
  cols = 12,
  rows,
  className,
  style,
  width,
  height,
  items,
  context,
}) => {
  return (
    <div
      className={cn("grid", classGenerator.gap(gap), className)}
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gridTemplateRows: rows ? `repeat(${rows}, 1fr)` : "auto",
        ...style,
        ...width,
        ...height,
      }}
    >
      {items?.map((item: any, index: number) => {
        return PageGenerator(
          {
            ...item,
            key: index,
          },
          context,
        );
      })}
    </div>
  );
};
