import React from "react";
import { Select, Input, Button, DatePicker, Modal, Table, message } from "antd";
import axios from "axios";
import { TableTransfer } from "../components";
import { formatTime } from "../utils/formatTime";
import { AiOutlineCar } from "react-icons/ai";
import { MdPersonAddAlt } from "react-icons/md";
import moment from "moment";
import { SelectInput } from "../components/shared/components";
import { PageGenerator } from "../app/uiFunctions/PageGenerator";
import { epochToGMT3Date } from "../utils/gmt3DateConverter";
import { useParams } from "react-router-dom";
import { linkTransferAndVoyager, unlinkTransferAndVoyager } from "../app/api";

export const EditTransfers = () => {
  const [vehicleModal, setVehicleModal] = React.useState(false);
  const [personalModal, setPersonalModal] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [voyagers, setVoyagers] = React.useState<any>([]);
  const [vehicles, setVehicles] = React.useState<any>([]);
  const [personals, setPersonals] = React.useState<any>([]);
  const [guides, setGuides] = React.useState<any>([]);

  const [data, setData] = React.useState<any>([]);

  const transferId = useParams<{ id: string }>().id;

  const importHandler = async (start: any, end: any) => {
    console.log("import");
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/transfers/${transferId}`)
      .then((res) => {
        const transfer = res.data;
        console.log("transfer", transfer);
        setVehicles(
          transfer.vehicles.map((i: any) => {
            console.log("i", i);
            return {
              _id: i.vehicle._id,
              name: i.vehicle.name,
              firm: i.firm?.name,
              vehicleFirmId: i.firm?._id,
              plate: i.plate,
              ouid: i.vehicle._id,
            };
          })
        );
        setPersonals(transfer.personals);
        setGuides(transfer.guides);
        setData(transfer.voyagers);
      });
  };

  const columns = [
    {
      dataIndex: "invoice",
      title: "Invoice",
      key: "invoice",
    },
    {
      dataIndex: ["flightInformations", "flightNumber"],
      title: "Flight Number",
      key: "flightNumber",
      render: (text: string, record: any) => {
        return (
          record.flightInformations.flightCarrier +
          record.flightInformations.flightNumber
        );
      },
    },
    {
      dataIndex: ["personalInformations", "name"],
      title: "Name",
      key: "name",
    },
    {
      dataIndex: ["transferExpectations", "date"],
      title: "Date",
      key: "date",
      render: (date: any) => {
        return moment.unix(date).format("DD/MM/YYYY");
      },
    },
    {
      dataIndex: ["transferExpectations", "time"],
      title: "Time",
      key: "time",
      render: (time: any) => {
        return formatTime(time);
      },
    },
    // delete action
    {
      dataIndex: "action",
      title: "Action",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <Button
            type="primary"
            danger
            onClick={async () => {
              try {
                await unlinkTransferAndVoyager(record._id, record.transfer);
                message.success({
                  content: "Voyager unlinked",
                  key: "unlinkVoyager",
                  duration: 2,
                });
                setData(data.filter((i: any) => i._id !== record._id));
              } catch (e: any) {
                message.error({
                  content: "Voyager unlinking failed",
                  key: "unlinkVoyager",
                  duration: 2,
                });
              }
            }}
          >
            -
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    importHandler(null, null);
  }, []);

  const createHandler = async () => {
    const voyagers: string[] = data.map((i: any) => i._id);

    const transferData = {
      voyagers: voyagers,
      vehicles: vehicles.map((i: any) => {
        return {
          vehicle: i._id,
          plate: i.plate,
          firm: i.vehicleFirmId,
        };
      }),
      personals: personals.map((i: any) => i._id),
      guides: guides.map((i: any) => i._id),
    };

    // ant message
    message.loading({
      content: "Creating Transfer...",
      key: "createTransfer",
      duration: 2,
    });

    try {
      await axios
        .patch(
          `${process.env.REACT_APP_API_URL}/api/transfers/` + transferId,
          transferData
        )
        .then((res) => {
          console.log(res);
        });

      // ant message
      message.success({
        content: "Transfer Created",
        key: "createTransfer",
        duration: 2,
      });

      // clear
      setData(data.filter((i: any) => !selectedRows.includes(i)));
      setVehicles([]);
      setPersonals([]);
      setGuides([]);
      setVoyagers([]);
      setSelectedRows([]);
    } catch (error) {
      // ant message
      message.error({
        content: "Transfer Creation Failed",
        key: "createTransfer",
        duration: 2,
      });
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-2">
          <div className="flex-grow flex flex-col space-y-2">
            <div>
              {PageGenerator({
                type: "form",
                name: "form",
                onSubmit: async (values: any, formik: any) => {
                  try {
                    const { voyager: newVoyager } =
                      await linkTransferAndVoyager(
                        JSON.parse(values.voyager)._id,
                        transferId
                      );
                    setData([newVoyager, ...data]);
                  } catch (error) {}
                },
                items: [
                  {
                    type: "xStack",
                    items: [
                      {
                        type: "field",
                        name: "voyager",
                        span: 8,
                        item: {
                          type: "select",
                          placeholder: "Select Voyager",
                          search: true,
                          mode: "default",
                          clear: true,
                          selfReturn: true,
                        },
                        remote: {
                          url: `${process.env.REACT_APP_API_URL}/api/voyagers?limit=5&inv&invoice={{value}}`,
                          value: "_id",
                          label: "invoice",
                        },
                      },
                      {
                        type: "button",
                        inputType: "submit",
                        value: "Add",
                        span: 1,
                      },
                    ],
                  },
                ],
              })}
            </div>
            <Table columns={columns} dataSource={data} rowKey={"_id"} />
          </div>
          <div className="w-96 flex flex-col space-y-4">
            <div className="w-full bg-slate-100 rounded-md border-2">
              <div className="w-full h-full flex items-start justify-center space-y-1 text-slate-500 pt-4 flex-col">
                <div className="flex items-center justify-center w-full space-x-2">
                  <AiOutlineCar className="text-2xl" />
                  <p>Select Veichles</p>
                </div>
                <div
                  className="flex-grow w-full flex flex-col space-y-1 items-center"
                  style={{
                    minHeight: "120px",
                  }}
                >
                  {vehicles.map((vehicle: any) => (
                    <div className="flex space-x-2">
                      <span>{vehicle.firm} | </span>
                      <span>{vehicle.name}</span>
                      <span>[{vehicle.plate}]</span>
                      <span
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          setVehicles(
                            vehicles.filter((v: any) => v.ouid !== vehicle.ouid)
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flekxx w-full">
                  {PageGenerator({
                    type: "form",
                    name: "form",
                    onSubmit: (values: any, formik: any) => {
                      setVehicles([
                        ...vehicles,
                        {
                          name: JSON.parse(values.vehicle.vh).name,
                          _id: JSON.parse(values.vehicle.vh)._id,
                          firm: JSON.parse(values.vehicle.vf).name,
                          vehicleFirmId: JSON.parse(values.vehicle.vf)._id,
                          plate: values.vehicle.plate,
                          ouid: Math.random(),
                        },
                      ]);
                      formik.resetForm();
                    },
                    items: [
                      {
                        type: "xStack",
                        items: [
                          {
                            type: "field",
                            fieldType: "object",
                            name: "vehicle",
                            span: 10,
                            items: [
                              {
                                type: "field",
                                name: "vf",
                                span: 1,
                                item: {
                                  type: "select",
                                  placeholder: "Select Firm",
                                  search: true,
                                  mode: "default",
                                  clear: true,
                                  selfReturn: true,
                                },
                                remote: {
                                  url: `${process.env.REACT_APP_API_URL}/api/carfirms?limit=5&search={{value}}`,
                                  value: "_id",
                                },
                              },
                              {
                                type: "field",
                                name: "vh",
                                span: 8,
                                item: {
                                  type: "select",
                                  placeholder: "Select Vehicle",
                                  search: true,
                                  mode: "default",
                                  clear: true,
                                  selfReturn: true,
                                },
                                remote: {
                                  url: `${process.env.REACT_APP_API_URL}/api/carTypes?limit=5&search={{value}}`,
                                  value: "_id",
                                },
                              },
                              {
                                type: "field",
                                name: "plate",
                                span: 4,
                                item: {
                                  type: "text",
                                  placeholder: "Plate",
                                },
                              },
                            ],
                          },
                          {
                            type: "button",
                            inputType: "submit",
                            value: "Add",
                            span: 2,
                          },
                        ],
                      },
                    ],
                  })}
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-md border-2">
              <div className="w-full h-full flex items-start justify-center space-y-1 text-slate-500 pt-4 flex-col">
                <div className="flex items-center justify-center w-full space-x-2">
                  <MdPersonAddAlt className="text-2xl" />
                  <p>Select Guides</p>
                </div>
                <div
                  className="flex-grow w-full flex flex-col space-y-1 items-center"
                  style={{
                    minHeight: "120px",
                  }}
                >
                  {guides.map((guide: any) => (
                    <div className="flex space-x-2">
                      <span>{guide.name}</span>
                      <span
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          setGuides(
                            guides.filter((v: any) => v.ouid !== guide.ouid)
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex w-full">
                  {PageGenerator({
                    type: "form",
                    name: "form",
                    onSubmit: (values: any, formik: any) => {
                      setGuides([
                        ...guides,
                        {
                          name: JSON.parse(values.guide).name,
                          _id: JSON.parse(values.guide)._id,
                          ouid: Math.random(),
                        },
                      ]);
                      formik.resetForm();
                    },
                    items: [
                      {
                        type: "xStack",
                        items: [
                          {
                            type: "field",
                            name: "guide",
                            span: 8,
                            item: {
                              type: "select",
                              placeholder: "Select Guide",
                              search: true,
                              mode: "default",
                              clear: true,
                              selfReturn: true,
                            },
                            remote: {
                              url: `${process.env.REACT_APP_API_URL}/api/guides?limit=5&search={{value}}`,
                              value: "_id",
                            },
                          },
                          {
                            type: "button",
                            inputType: "submit",
                            value: "Add",
                            span: 1,
                          },
                        ],
                      },
                    ],
                  })}
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-md border-2">
              <div className="w-full h-full flex items-start justify-center space-y-1 text-slate-500 pt-4 flex-col">
                <div className="flex items-center justify-center w-full space-x-2">
                  <MdPersonAddAlt className="text-2xl" />
                  <p>Select Personals</p>
                </div>
                <div
                  className="flex-grow w-full flex flex-col items-center"
                  style={{
                    minHeight: "120px",
                  }}
                >
                  {personals.map((personal: any) => (
                    <div className="flex space-x-2">
                      <span>{personal.name}</span>
                      <span
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          setPersonals(
                            personals.filter(
                              (v: any) => v.ouid !== personal.ouid
                            )
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex w-full">
                  {PageGenerator({
                    type: "form",
                    name: "form",
                    onSubmit: (values: any, formik: any) => {
                      setPersonals([
                        ...personals,
                        {
                          name: JSON.parse(values.personal).name,
                          _id: JSON.parse(values.personal)._id,
                          ouid: Math.random(),
                        },
                      ]);
                      formik.resetForm();
                    },
                    items: [
                      {
                        type: "xStack",
                        items: [
                          {
                            type: "field",
                            name: "personal",
                            span: 8,
                            item: {
                              type: "select",
                              placeholder: "Select Personal",
                              search: true,
                              mode: "default",
                              clear: true,
                              selfReturn: true,
                            },
                            remote: {
                              url: `${process.env.REACT_APP_API_URL}/api/personals?limit=5&search={{value}}`,
                              value: "_id",
                            },
                          },
                          {
                            type: "button",
                            inputType: "submit",
                            value: "Add",
                            span: 1,
                          },
                        ],
                      },
                    ],
                  })}
                </div>
              </div>
            </div>

            <Button
              className="h-12"
              block
              type="primary"
              onClick={createHandler}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title="Select Vehicle"
        open={vehicleModal}
        onOk={() => setVehicleModal(false)}
        onCancel={() => setVehicleModal(false)}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
      <Modal
        title="Select Personal"
        open={personalModal}
        onOk={() => setPersonalModal(false)}
        onCancel={() => setPersonalModal(false)}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};
