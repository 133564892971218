import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/restaurants/${record._id}`} className="font-semibold">
        {record.code}
      </Link>
    ),
  },
  {
    title: "Restaurant Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/restaurants/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
  {
    title: "Province",
    dataIndex: "province._id",
    key: "province._id",
    dataType: "string",
    render: (_id, record) => <div>{record?.province?.name}</div>,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    dataType: "string",
    render: (_id, record) => (
      <a href={`tel:${record.phone}`}>
        {formatText("phoneNumberWithCountryCode", record.phone)}
      </a>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    dataType: "string",
    render: (_id, record) => (
      <a href={`mailto:${record.email}`}>{record.email}</a>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "code",
                  label: "Code",
                  span: 2,
                  item: {
                    type: "text",
                    placeholder: "code",
                    format: {
                      onChange: "trim",
                    },
                  },
                  validations: {
                    required: {
                      message: "Code is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "name",
                  label: "Restaurant Name",
                  span: 10,
                  item: {
                    type: "text",
                    placeholder: "restaurant name",
                    format: {
                      onChange: "removeExtraSpaces",
                      onSubmit: "trim",
                    },
                  },
                  validations: {
                    required: {
                      message: "Restaurant name is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "address",
                  label: "Restaurant Address",
                  span: 12,
                  item: {
                    type: "textarea",
                    placeholder: "restaurant address",
                    rows: 2,
                    format: {
                      onChange: "removeExtraSpaces",
                      onSubmit: "trim",
                    },
                  },
                  validations: {
                    required: {
                      message: "Restaurant address is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "province",
                  label: "Province",
                  validations: {
                    required: true,
                  },
                  span: 12,
                  item: {
                    type: "select",
                    placeholder: "Select province",
                    search: true,
                    mode: "default",
                  },
                  remote: {
                    url: `${process.env.REACT_APP_API_URL}/api/provinces?search={{value}}`,
                    fetchOnMount: true,
                    value: "_id",
                  },
                },
                {
                  type: "field",
                  name: "phone",
                  label: "Phone",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "phone",
                    format: {
                      onChange: "phoneNumberWithCountryCode",
                      onSubmit: "dbPhoneNumber",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^\+\d{1,3} \d{3,14}$/,
                      message: "Telefon numarası geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "email",
                  label: "E-mail",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "email",
                    format: {
                      onChange: "trim",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                      message: "E-posta adresi geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  fieldType: "array",
                  name: "accounts",
                  label: "Accounts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "accountNumber",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "currency",
                        label: "Currency",
                        span: 3,
                        item: {
                          type: "select",
                          placeholder: "USD",
                          mode: "default",
                        },
                        options: [
                          {
                            value: "TRY",
                            label: "TRY",
                          },
                          {
                            value: "USD",
                            label: "USD",
                          },
                          {
                            value: "EUR",
                            label: "EUR",
                          },
                        ],
                      },
                      {
                        type: "field",
                        name: "iban",
                        label: "IBAN",
                        span: 9,
                        item: {
                          type: "text",
                          placeholder: "iban",
                          format: {
                            onChange: "iban",
                            onSubmit: "removeSpaces",
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  type: "field",
                  fieldType: "array",
                  name: "contacts",
                  label: "Contacts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "contactinfo",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "name",
                        label: "Name",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "name",
                          format: {
                            onChange: "removeExtraSpaces",
                            onSubmit: "trim",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "phone",
                        label: "Phone",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "phone",
                          format: {
                            onChange: "phoneNumberWithCountryCode",
                            onSubmit: "dbPhoneNumber",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^\+\d{1,3} \d{3,14}$/,
                            message: "Telefon numarası geçersiz",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "email",
                        label: "E-mail",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "email",
                          format: {
                            onChange: "trim",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                            message: "E-posta adresi geçersiz",
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
        {
          type: "yStack",
          gap: 4,
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  fieldType: "array",
                  name: "packages",
                  label: "Packages",
                  span: 12,
                  item: {
                    type: "field",
                    name: "package",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "code",
                        label: "Code",
                        span: 2,
                        item: {
                          type: "text",
                          placeholder: "code",
                          format: {
                            onChange: "trim",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "name",
                        label: "Package Title",
                        span: 10,
                        item: {
                          type: "text",
                          placeholder: "title",
                          format: {
                            onChange: "removeExtraSpaces",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "description",
                        span: 12,
                        item: {
                          type: "textarea",
                          placeholder: "description",
                          rows: 2,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class RestaurantController extends StandartCollectionController {
  constructor() {
    super("Restaurant", "restaurants", {
      columns,
      form,
    });
  }
}
