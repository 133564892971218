import React from "react";
import { BsUpload } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import cn from "classnames";

export const ImportFile: React.FC<{
  onDrop: (acceptedFiles: File[]) => void;
  accept: any;
  className?: string;
}> = ({ onDrop, accept, className }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
  });

  return (
    <div className={cn("corner-border p-0.5")}>
      <div
        className={cn("cursor-pointer transition-all", className, {
          "bg-blue-100": isDragActive,
          "bg-white": !isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className="h-full w-full flex flex-col items-center justify-center">
          <BsUpload className="text-4xl text-slate-400 bg" />
          <span className="text-sm text-slate-400">
            {isDragActive
              ? "Drop the file here ..."
              : "Drag the file here or click to select it"}
          </span>
          <span className=" text-slate-400 text-xs">
            Accepted Formats : Robot Files
          </span>
        </div>
      </div>
    </div>
  );
};
