import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/guides/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    dataType: "string",
    render: (_id, record) => (
      <a href={`tel:${record.phone}`}>
        {formatText("phoneNumberWithCountryCode", record.phone)}
      </a>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    dataType: "string",
    render: (_id, record) => (
      <a href={`mailto:${record.email}`}>{record.email}</a>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Name",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "name",
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                  validations: {
                    required: {
                      message: "name is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "phone",
                  label: "Phone",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "phone",
                    format: {
                      onChange: "phoneNumberWithCountryCode",
                      onSubmit: "dbPhoneNumber",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^\+\d{1,3} \d{3,14}$/,
                      message: "Telefon numarası geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "email",
                  label: "E-mail",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "email",
                    format: {
                      onChange: "trim",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                      message: "E-posta adresi geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "accountNumber",
                  fieldType: "object",
                  compact: true,
                  span: 12,
                  items: [
                    {
                      type: "field",
                      name: "currency",
                      label: "Currency",
                      span: 3,
                      item: {
                        type: "select",
                        placeholder: "USD",
                        mode: "default",
                      },
                      options: [
                        {
                          value: "TRY",
                          label: "TRY",
                        },
                        {
                          value: "USD",
                          label: "USD",
                        },
                        {
                          value: "EUR",
                          label: "EUR",
                        },
                      ],
                    },
                    {
                      type: "field",
                      name: "iban",
                      label: "IBAN",
                      span: 9,
                      item: {
                        type: "text",
                        placeholder: "iban",
                        format: {
                          onChange: "iban",
                          onSubmit: "removeSpaces",
                        },
                      },
                    },
                  ],
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
        {
          type: "yStack",
          gap: 4,
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "fees",
                  label: "Fees",
                  validations: {
                    required: true,
                  },
                  span: 12,
                  item: {
                    type: "select",
                    placeholder: "Select Fee Option",
                    search: true,
                    mode: "default",
                  },
                  remote: {
                    url: `${process.env.REACT_APP_API_URL}/api/guidefees?search={{value}}`,
                    fetchOnMount: true,
                    value: "_id",
                  },
                },
                {
                  type: "field",
                  name: "licanceNumber",
                  label: "Licance Number",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "licance number",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class CiceroneController extends StandartCollectionController {
  constructor() {
    super("Guide", "guides", {
      columns,
      form,
    });
  }
}
