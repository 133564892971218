import React, { useEffect } from "react";
import { useFormik } from "formik";
import { IntRange } from "../../types";
import { PageGenerator } from "../../app/uiFunctions/PageGenerator";
import {
  getFormData,
  getFormValidations,
} from "../../app/uiFunctions/getFormData";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRecord } from "../../app/functions/createRecord";
import { readRecord } from "../../app/functions/readRecord";
import { updateRecord } from "../../app/functions/updateRecord";
import { validate as validateFunction } from "../../utils/validations";
import { useParams } from "react-router-dom";
import { Spin, message } from "antd";

interface FormProps {
  form: formtype;
  collection?: string;
  layout?: any;
  onSubmit?: (data: any, formik?: any) => Promise<any>;
  onSuccess?: (e: any) => void | "message";
  onError?: (e: any) => void | "message";
  onChange?: (data: any) => void;
  beforeSubmit?: (data: any) => void;
  invalidation?: string;
  updateId?: string;
  inputSize?: "small" | "large";
}

interface formtype {
  layout?: {
    columns?: IntRange<1, 13>;
    rows?: IntRange<1, 7>;
  };
  items: any;
}

const setErroredFieldsTouched = (formik: any) => {
  Object.keys(formik.values).forEach((key) => {
    formik.setFieldTouched(key);
  });
};

export const Form: React.FC<FormProps> = ({
  form,
  onSubmit,
  onSuccess,
  onError,
  onChange,
  collection,
  invalidation,
  updateId,
  inputSize,
}) => {
  console.log("form", collection);

  invalidation = invalidation || collection;

  const params = useParams();
  const type = onSubmit
    ? "custom"
    : params?.id || updateId
    ? "update"
    : "create";

  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);
  const [error404, setError404] = React.useState(true);

  const queryClient = useQueryClient();

  const mutationHandler = async (data: any) => {
    console.log("type", type);
    if (type === "create") {
      await createRecord(collection!, data);
    }
    if (type === "update") {
      await updateRecord(collection!, updateId || params.id!, data);
    }
    if (type === "custom") {
      await onSubmit!(data, formik);
    }
  };

  // Mutations
  const mutation = useMutation(mutationHandler, {
    onSettled: () => {
      setLoading(false);
      formik.setSubmitting(false);
    },
    onSuccess: async (e) => {
      invalidation && (await queryClient.invalidateQueries([invalidation]));
      if (typeof onSuccess !== "string" && onSuccess) {
        onSuccess(e);
      } else {
        message.success({
          content: "Success!",
          key: `${collection || "custom"}-update`,
          duration: 2,
        });
      }
      type === "create" && formik.resetForm();
    },
    onError: (e: any) => {
      message.error({
        content: e.response.data.message,
        key: `${collection || "custom"}-update`,
        duration: 2,
      });
    },
  });

  const validations = getFormValidations(form?.items);

  const validate = (values: any) => {
    return validateFunction(values, validations);
  };

  const formik = useFormik({
    initialValues: getFormData(form?.items),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: (values) => {
      setLoading(true);
      mutation.mutate(values);
    },
    validate,
  });

  const { resetForm } = formik;

  useEffect(() => {
    resetForm();
  }, [params._id, updateId, collection, resetForm]);

  useEffect(() => {
    onChange ? onChange(formik.values) : console.log(formik.values);
  }, [formik.values]);

  useEffect(() => {
    console.log(updateId);
    if (type === "update" || updateId) {
      readRecord(collection!, updateId || params.id || "")
        .then((data) => {
          delete data.data._id;
          delete data.data.__v;
          delete data.data.createdAt;
          delete data.data.updatedAt;
          formik.setValues(data.data);
          setError404(false);
          setFetching(false);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setError404(true);
          }
          setFetching(false);
        });
    } else {
      setError404(false);
      setFetching(false);
    }
  }, [updateId, params.id]);

  if (fetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-2xl">
          <Spin />
        </div>
      </div>
    );
  }

  if (error404) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-2xl">404</div>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length === 0) {
            message.loading({
              content: "Loading...",
              key: `${collection || "custom"}-update`,
            });
            formik.submitForm();
          } else {
            setErroredFieldsTouched(formik);
          }
        });
      }}
      className="h-full w-full"
    >
      {form.items.map((item: any, index: number) => {
        return PageGenerator(
          {
            ...item,
            key: index,
          },
          {
            formik,
            inputSize: inputSize,
            isLoading: loading,
          },
        );
      })}
    </form>
  );
};

// const validate = (values: any) => {
//   const errors: any = {};
//   if (!values.firstName) {
//     errors.firstName = "Required";
//   } else if (values.firstName.length > 15) {
//     errors.firstName = "Must be 15 characters or less";
//   }

//   if (!values.lastName) {
//     errors.lastName = "Required";
//   } else if (values.lastName.length > 20) {
//     errors.lastName = "Must be 20 characters or less";
//   }

//   if (!values.email) {
//     errors.email = "Required";
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = "Invalid email address";
//   }

//   return errors;
// };
