import { BasicCollectionController, ICollectionController } from "../../types";
import { ProvinceController } from "./ProvinceController";
import { HotelController } from "./HotelController";
import { PortController } from "./PortController";
import { AirportController } from "./AirportController";
import { RouteController } from "./RouteController";
import { RestaurantController } from "./RestaurantController";
import { ProductController } from "./ProductController";
import { CarFirmController } from "./CarFirmController";
import { CarTypeController } from "./CarTypeController";
import { ClientController } from "./ClientController";
import { PersonalFirmController } from "./PersonalFirmController";
import { PersonalController } from "./PersonalController";
import { MuseumController } from "./MuseumController";
import { GuideFeeController } from "./GuideFee";
import { CiceroneController } from "./CiceroneController";
import { PackageController } from "./PackageController";
import { VoyagerController } from "./VoyagersController";
import { UserController } from "./UserController";
import { CruiseController } from "./CruiseController";
import { TransferController } from "./TransferController";
import { OperationController } from "./OperationController";

export const Provinces: ICollectionController = new ProvinceController();
export const Hotels: ICollectionController = new HotelController();
export const Ports: ICollectionController = new PortController();
export const Airports: ICollectionController = new AirportController();
export const Routes: ICollectionController = new RouteController();
export const Restaurants: ICollectionController = new RestaurantController();
export const Products: ICollectionController = new ProductController();
export const CarFirms: ICollectionController = new CarFirmController();
export const CarTypes: ICollectionController = new CarTypeController();
export const Clients: ICollectionController = new ClientController();
export const PersonalFirms: ICollectionController =
  new PersonalFirmController();
export const Personals: ICollectionController = new PersonalController();
export const Museums: ICollectionController = new MuseumController();
export const GuideFees: ICollectionController = new GuideFeeController();
export const Cicerones: ICollectionController = new CiceroneController();
export const Packages: ICollectionController = new PackageController();
export const Voyagers: ICollectionController = new VoyagerController();
export const Users: ICollectionController = new UserController();
export const Cruises: ICollectionController = new CruiseController();
export const Transfers: ICollectionController = new TransferController();
export const Operations: ICollectionController = new OperationController();
