import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "Firm Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/personals/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    dataType: "string",
    render: (_id, record) => (
      <a href={`tel:${record.phone}`}>
        {formatText("phoneNumberWithCountryCode", record.phone)}
      </a>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    dataType: "string",
    render: (_id, record) => (
      <a href={`mailto:${record.email}`}>{record.email}</a>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Name",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "name",
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                  validations: {
                    required: {
                      message: "name is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "phone",
                  label: "Phone",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "phone",
                    format: {
                      onChange: "phoneNumberWithCountryCode",
                      onSubmit: "dbPhoneNumber",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^\+\d{1,3} \d{3,14}$/,
                      message: "Telefon numarası geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "email",
                  label: "E-mail",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "email",
                    format: {
                      onChange: "trim",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                      message: "E-posta adresi geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "firm",
                  label: "Firm",
                  span: 12,
                  item: {
                    type: "select",
                    placeholder: "firm",
                    mode: "default",
                  },
                  remote: {
                    url: `${process.env.REACT_APP_API_URL}/api/personalfirms?search={{value}}`,
                    fetchOnMount: true,
                  },
                },
                {
                  type: "field",
                  name: "serviceType",
                  label: "Service Type",
                  span: 12,
                  item: {
                    type: "select",
                    placeholder: "service type",
                    mode: "default",
                  },
                  options: [
                    {
                      label: "Service Type 1",
                      value: "serviceType1",
                    },
                    {
                      label: "Service Type 2",
                      value: "serviceType2",
                    },
                  ],
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
        {
          type: "yStack",
          gap: 4,
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  fieldType: "array",
                  name: "periods",
                  label: "Periods",
                  span: 12,
                  item: {
                    type: "field",
                    name: "period",
                    fieldType: "object",
                    //compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "range",
                        label: "Range",
                        span: 5,
                        item: {
                          type: "DateRange",
                          placeholder: "end date",
                          format: {
                            date: "DD/MM/YYYY",
                          },
                        },
                      },
                      {
                        type: "field",
                        fieldType: "object",
                        name: "price",
                        //label: "Price",
                        //compact: true,
                        span: 7,
                        items: [
                          {
                            type: "field",
                            name: "currency",
                            label: "Currency",
                            validations: {
                              required: true,
                            },
                            span: 3,
                            item: {
                              type: "select",
                              placeholder: "Select currency",
                              mode: "default",
                              sort: "desc",
                            },
                            options: [
                              {
                                value: "TRY",
                                label: "TRY",
                              },
                              {
                                value: "USD",
                                label: "USD",
                              },
                              {
                                value: "EUR",
                                label: "EUR",
                              },
                            ],
                          },
                          {
                            type: "field",
                            name: "price",
                            label: "Price",
                            span: 4,
                            dataType: "number",
                            item: {
                              type: "number",
                              placeholder: "0",
                              format: {
                                step: 0.01,
                                digitSeparator: ",",
                              },
                              limitations: {
                                min: 0,
                              },
                            },
                          },
                          {
                            type: "field",
                            name: "vat",
                            label: "VAT",
                            span: 3,
                            item: {
                              type: "number",
                              placeholder: "0",
                              format: {
                                step: 1,
                              },
                              limitations: {
                                min: 0,
                                max: 100,
                              },
                            },
                          },
                          {
                            type: "field",
                            name: "vatIncluded",
                            label: "Included",
                            span: 2,
                            item: {
                              type: "switch",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class PersonalController extends StandartCollectionController {
  constructor() {
    super("Personal", "personals", {
      columns,
      form,
    });
  }
}
