import React from "react";
import { DatePicker as AntDate } from "antd";

import dayjs from "dayjs";
import {
  epochToGMT3Date,
  gmt3DateToEpoch,
} from "../../../utils/gmt3DateConverter";

export const DatePicker: React.FC<any> = ({
  value,
  onChange,
  format,
  isEndOfDate = false,
  name,
}) => {
  //const time: any = dayjs(value, "YYYY-MM-DD");

  const systemDateFormat = "YYYY-MM-DD";

  return (
    <AntDate
      value={value ? dayjs(epochToGMT3Date(value), systemDateFormat) : null}
      className="w-full"
      format={format || systemDateFormat}
      onChange={(val: any) => {
        console.log(val);
        onChange &&
          onChange({
            target: {
              name,
              value: gmt3DateToEpoch(
                dayjs(val).format(systemDateFormat),
                isEndOfDate,
              ),
            },
          });
      }}
    />
  );
};
