import { CopyableText } from "../../components";
import { StandartCollectionController, column } from "../../types";

const columns: column[] = [
  {
    title: "Id",
    dataIndex: "_id",
    key: "_id",
    dataType: "string",
    render: (text: string) => <CopyableText>{text}</CopyableText>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (text: string) => text.toUpperCase(),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    dataType: "string",
    render: (text: string) => text.capitalize(),
  },
  {
    title: "Hotel",
    dataIndex: ["hotel", "name"],
    key: ["hotel", "name"],
    dataType: "string",
    render: (text: string) => text?.capitalize(),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "grid",
      space: 2,
      items: [
        {
          type: "field",
          name: "name",
          label: "Name",
          span: 12,
          item: {
            type: "text",
            placeholder: "name",
            format: {
              onChange: "removeExtraSpaces",
              onsubmit: "trim",
            },
          },
          validations: {
            required: true,
          },
        },
        {
          type: "field",
          name: "type",
          label: "Type",
          span: 12,
          item: {
            type: "select",
            placeholder: "Select type",
            search: true,
            mode: "default",
          },
          options: [
            {
              label: "Transfer",
              value: "Transfer",
            },
            {
              label: "Tour",
              value: "Tour",
            },
            {
              label: "Hotel Only",
              value: "HotelOnly",
            },
          ],
        },
        {
          type: "field",
          name: "hotel",
          label: "Hotel",
          validations: {
            required: false,
          },
          span: 9,
          item: {
            type: "select",
            placeholder: "Select province",
            search: true,
            mode: "default",
          },
          remote: {
            url: `${process.env.REACT_APP_API_URL}/api/hotels?search={{value}}`,
            fetchOnMount: true,
            value: "_id",
          },
        },
        {
          type: "button",
          inputType: "submit",
          value: "Submit",
          span: 12,
        },
      ],
    },
  ],
};

export class PackageController extends StandartCollectionController {
  constructor() {
    super("Package", "packages", {
      columns,
      form,
    });
  }
}
