import { createRecord } from "./functions/createRecord";
import axios from "axios";

export { createRecord };

//const endPoint = "";

const endPoint =
  process.env.REACT_APP_API_URL || new URL(window.location.href).origin;

axios.interceptors.request.use(
  function (config: any) {
    const { origin } = new URL(config.url);
    const allowedOrigins = [endPoint];
    const token = localStorage.getItem("access-token");

    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const fetchLogin = async (email: any, password: any) => {
  const { data } = await axios.post(`${endPoint}/api/auth/login`, {
    email,
    password,
  });
  return data;
};

export const fetchMe = async () => {
  const { data } = await axios.get(`${endPoint}/api/auth/me`);
  return data;
};

export const refreshToken = async (token: any) => {
  const { data } = await axios.post(`${endPoint}/api/auth/refreshtoken`, {
    refreshToken: token,
  });
  return data;
};

export const fetchData = async (
  table: any,
  pager: { pageSize: any },
  page: any,
  filterString: string | any[],
) => {
  const { data } = await axios.get(
    `${endPoint}/api/${table}?limit=${pager.pageSize}&page=${page}${
      filterString.length > 0 ? `&filter=[${filterString}]` : ""
    }`,
  );
  return data;
};

export const getRegions = async () => {
  const { data } = await axios.get(`${endPoint}/api/regions`);
  return data;
};

export const addRegions = async (values: any) => {
  const { data } = await axios.post(`${endPoint}/api/regions`, values);
  return data;
};

export const updateRegion = async ({ id, values }: any) => {
  const { data } = await axios.post(
    `${endPoint}/api/regions/update/${id}`,
    values,
  );
  return data;
};

export const removeRegion = async (id: any) => {
  const { data } = await axios.get(`${endPoint}/api/regions/delete/${id}`);
  return data;
};

export const userRegister = async (values: any) => {
  const { data } = await axios.post(`${endPoint}/api/auth/register`, values);
  return data;
};

export const removeUser = async (id: any) => {
  const { data } = await axios.get(`${endPoint}/api/users/delete/${id}`);
  return data;
};

export const updateUser = async ({ id, values }: any) => {
  const { data } = await axios.post(
    `${endPoint}/api/users/update/${id}`,
    values,
  );
  return data;
};

export const fetchUser = async (id: any) => {
  const { data } = await axios.get(`${endPoint}/api/users/${id}`);
  return data;
};

export const addOrder = async (values: any) => {
  const { data } = await axios.post(`${endPoint}/api/orders/create`, values);
  return data;
};

export const approveOrder = async (id: any, note: any) => {
  const { data } = await axios.post(`${endPoint}/api/orders/approve/${id}`, {
    responseNote: note,
  });
  return data;
};

export const rejectOrder = async (id: any, note: any) => {
  const { data } = await axios.post(`${endPoint}/api/orders/reject/${id}`, {
    responseNote: note,
  });
  return data;
};

export const exportOrders = async (ids: any) => {
  const { data } = await axios.post(`${endPoint}/api/orders/export`, {
    ordersIdList: ids,
  });
  return data;
};

export const ordermailInfo = async (id: any) => {
  const { data } = await axios.get(
    `${endPoint}/api/orders/orderinfomail/${id}`,
  );
  return data;
};

export const pendingOrders = async () => {
  const { data } = await axios.get(
    `${endPoint}/api/orders?filter=[status:pending:same]`,
  );
  return data;
};

export const outStock = async () => {
  const { data } = await axios.get(`${endPoint}/api/products/outstock`);
  return data;
};

export const countProducts = async () => {
  const { data } = await axios.get(`${endPoint}/api/products/count`);
  return data;
};

export const countCustomers = async () => {
  const { data } = await axios.get(`${endPoint}/api/customers/count`);
  return data;
};

export const countOrders = async () => {
  const { data } = await axios.get(`${endPoint}/api/orders/count`);
  return data;
};

export const updateCustomer = async (id: any, values: any) => {
  const { data } = await axios.post(
    `${endPoint}/api/customers/update/${id}`,
    values,
  );
  return data;
};

export const changePassword = async (values: any) => {
  const { data } = await axios.post(
    `${endPoint}/api/auth/changepassword`,
    values,
  );
  return data;
};

export const resetPassword = async (values: any) => {
  const { data } = await axios.post(`${endPoint}/api/auth/forgotpass`, values);
  return data;
};

export const getSettings = async () => {
  const { data } = await axios.get(`${endPoint}/api/settings`);
  return data;
};

export const updateSettings = async (key: any, values: any) => {
  const { data } = await axios.post(
    `${endPoint}/api/settings/update?key=${key}`,
    values,
  );
  return data;
};

export const getTemps = async () => {
  const { data } = await axios.get(`${endPoint}/api/mailtemps`);
  return data;
};

export const updateTemps = async (key: any, values: any) => {
  const { data } = await axios.post(
    `${endPoint}/api/mailtemps/update?key=${key}`,
    values,
  );
  return data;
};

export const exportedOrders = async (ids: any) => {
  const { data } = await axios.post(`${endPoint}/api/orders/exported`, {
    ordersIdList: ids,
  });
  return data;
};

export const exportOrdersToMicro = async (endpoint: string, values: any) => {
  const { data } = await axios.post(endpoint, values);
  return data;
};

export const unlinkTransferAndVoyager = async (id: any, transfer: any) => {
  const { data } = await axios.patch(
    `${endPoint}/api/voyagers/${id}/transferunlink/${transfer}`,
    {}
  );
  return data;
};

export const linkTransferAndVoyager = async (id: any, transfer: any) => {
  const { data } = await axios.patch(
    `${endPoint}/api/voyagers/${id}/transferlink/${transfer}`,
    {}
  );
  return data;
};