import { Avatar, Switch } from "antd";
import { CopyableText } from "../../components";
import { BasicCollectionController, column } from "../../types";
import axios from "axios";

const columns: column[] = [
  {
    title: "",
    dataIndex: "image",
    key: "image",
    dataType: "string",
    render: (text: string) => <Avatar src={text} />,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    dataType: "string",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    dataType: "string",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    key: "_id",
    dataType: "boolean",
    render: (text: any, record: any) => {
      return (
        <Switch
          defaultChecked={text}
          onChange={(e) => {
            axios.post(
              `${process.env.REACT_APP_API_URL}/api/users/activity/` +
                record._id,
              {
                isActive: e,
              },
            );
          }}
        />
      );
    },
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "grid",
      space: 2,
      items: [
        {
          type: "field",
          name: "name",
          label: "Name",
          span: 12,
          item: {
            type: "text",
            placeholder: "John Doe",
          },
          validations: {
            required: {
              message: "name is required",
            },
          },
        },
        {
          type: "field",
          name: "email",
          label: "Email",
          span: 12,
          item: {
            type: "text",
            placeholder: "johndoe@example.com",
          },
          validations: {
            required: {
              message: "email is required",
            },
          },
        },
        {
          type: "field",
          name: "role",
          label: "Role",
          span: 12,
          validations: {
            required: true,
          },
          item: {
            type: "select",
            placeholder: "Select Role",
            mode: "default",
          },
          options: [
            {
              label: "Admin",
              value: "Admin",
            },
            {
              label: "Personal",
              value: "Personal",
            },
            {
              label: "Guide",
              value: "Guide",
            },
          ],
        },
        {
          type: "field",
          name: "systemUser",
          label: "Personal Card",
          span: 12,
          item: {
            type: "select",
            placeholder: "Select Personal Card",
            search: true,
            mode: "default",
            clear: true,
          },
          remote: {
            url: `${process.env.REACT_APP_API_URL}/api/{{$role}}s?limit=5&search={{value}}`,
            value: "_id",
          },
          dependents: ["role"],
        },
        {
          type: "button",
          inputType: "submit",
          value: "Submit",
          span: 12,
        },
      ],
    },
  ],
};

export class UserController extends BasicCollectionController {
  constructor() {
    super("User", "users", {
      columns,
      form: form,
    });
  }
}
