import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { ISelectInput } from "../../../types/uiComponentTypes";
import cn from "classnames";
import axios from "axios";
import { accessNestedObject } from "../../../utils/accessNestedObjects";

export const SelectInput: ISelectInput = ({
  options = [],
  onChange,
  onBlur,
  className,
  name,
  placeholder,
  value,
  search,
  mode = "default",
  sort,
  remote = false,
  clear = false,
  size,
  disabled,
  dependent,
  selfReturn,
}) => {
  const [data, setData] = useState<any>([]);
  const [selected, setSelected] = useState<any>(
    remote
      ? value
        ? value[remote?.value || "_id"]
        : null
      : value
      ? value
      : null,
  );

  const fetch = (value: string, callback: any) => {
    axios
      .get(
        replaceDependentValues(
          remote?.url?.replace("{{value}}", value || ""),
          dependent,
        ),
        {
          headers: {
            Authorization: remote?.token
              ? `Bearer ${localStorage.getItem(remote.token)}`
              : `Bearer ${localStorage.getItem("access-token")}`,
          },
        },
      )
      .then((response: any) => {
        callback(accessNestedObject(response.data, remote?.data || "data"));
      })
      .catch((error) => {
        setData([]);
        console.log(error);
      });
  };

  const handleSearch = (val: string) => {
    fetch(val, setData);
  };

  useEffect(() => {
    if (remote !== false) {
      if (dependent) {
        if (!hasEmptyOrUndefined(dependent)) {
          handleSearch("");
        }
      } else {
        handleSearch("");
      }
    }
  }, []);

  useEffect(() => {
    if (!hasEmptyOrUndefined(dependent) && remote !== false) {
      handleSearch("");
    }
  }, [dependent]);

  useEffect(() => {
    const val = {
      target: {
        name: name,
        value: selected || null,
      },
    };
    onChange?.(val);
  }, [selected]);

  useEffect(() => {
    if (!value) {
      setSelected(undefined);
    }
  }, [value]);

  return (
    <Select
      disabled={disabled || hasEmptyOrUndefined(dependent)}
      showSearch={search}
      allowClear={clear}
      placeholder={placeholder}
      value={selected}
      mode={mode === "default" ? undefined : mode}
      optionFilterProp="children"
      className={cn("w-full", className)}
      onChange={(e) => setSelected(e)}
      onBlur={(e) => {
        onBlur?.({
          target: {
            name: name,
          },
        });
      }}
      filterOption={(input, option: any) =>
        (option?.label ?? "")
          .toLocaleLowerCase("tr-TR")
          .includes(input.toLocaleLowerCase("tr-TR"))
      }
      filterSort={
        sort === "asc"
          ? (optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
          : sort === "desc"
          ? (optionA, optionB) =>
              (optionB?.label ?? "")
                .toLowerCase()
                .localeCompare((optionA?.label ?? "").toLowerCase())
          : undefined
      }
      options={
        remote
          ? data?.map((i: any) => {
              console.log(selfReturn);
              return {
                value: selfReturn
                  ? JSON.stringify(i)
                  : accessNestedObject(i, remote?.value || "_id"),
                label: accessNestedObject(
                  i,
                  remote?.label || "name",
                ).capitalize(),
              };
            })
          : options
      }
      onSearch={remote && !remote.fetchOnMount ? handleSearch : undefined}
      size={size}
    />
  );
};

function replaceDependentValues(str: string, dependents: any) {
  const regex = /\{\{(\$[a-zA-Z0-9_]+)\}\}/g;

  return str.replace(regex, (_, match) => {
    const dependentKey = match.slice(1);
    if (dependents.hasOwnProperty(dependentKey)) {
      return dependents[dependentKey];
    }
    return match;
  });
}

function hasEmptyOrUndefined(dependents: any) {
  return Object.values(dependents).some(
    (value) => value === "" || value === undefined || value === null,
  );
}
