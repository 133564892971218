import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";
import { Drawer } from "antd";
import { TableActionButton } from "../../components";
import { TbEdit, TbPackage } from "react-icons/tb";
import { FaTrash } from "react-icons/fa";
import React from "react";
import axios from "axios";
import { PageGenerator } from "../uiFunctions/PageGenerator";

const columns: column[] = [
  {
    title: "Hotel Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/hotels/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
  {
    title: "Province",
    dataIndex: "province._id",
    key: "province._id",
    dataType: "string",
    render: (_id, record) => <div>{record?.province?.name}</div>,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    dataType: "string",
    render: (_id, record) => (
      <a href={`tel:${record.phone}`}>
        {formatText("phoneNumberWithCountryCode", record.phone)}
      </a>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    dataType: "string",
    render: (_id, record) => (
      <a href={`mailto:${record.email}`}>{record.email}</a>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Hotel Name",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "hotel name",
                    format: {
                      onChange: "removeExtraSpaces",
                      onSubmit: "trim",
                    },
                  },
                  validations: {
                    required: {
                      message: "Otel ismi alanı zorunludur",
                    },
                  },
                },
                {
                  type: "field",
                  name: "address",
                  label: "Hotel Address",
                  span: 12,
                  item: {
                    type: "textarea",
                    placeholder: "hotel address",
                    rows: 2,
                    format: {
                      onChange: "removeExtraSpaces",
                      onSubmit: "trim",
                    },
                  },
                },
                {
                  type: "field",
                  name: "province",
                  label: "Province",
                  validations: {
                    required: true,
                  },
                  span: 12,
                  item: {
                    type: "select",
                    placeholder: "Select province",
                    search: true,
                    mode: "default",
                  },
                  remote: {
                    url: `${process.env.REACT_APP_API_URL}/api/provinces?search={{value}}`,
                    fetchOnMount: true,
                    value: "_id",
                  },
                },
                {
                  type: "field",
                  name: "phone",
                  label: "Phone",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "phone",
                    format: {
                      onChange: "phoneNumberWithCountryCode",
                      onSubmit: "dbPhoneNumber",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^\+\d{1,3} \d{3,14}$/,
                      message: "Telefon numarası geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "email",
                  label: "E-mail",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "email",
                    format: {
                      onChange: "trim",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                      message: "E-posta adresi geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  fieldType: "array",
                  name: "accounts",
                  label: "Accounts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "accountNumber",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "currency",
                        label: "Currency",
                        span: 3,
                        item: {
                          type: "select",
                          placeholder: "USD",
                          mode: "default",
                        },
                        options: [
                          {
                            value: "TRY",
                            label: "TRY",
                          },
                          {
                            value: "USD",
                            label: "USD",
                          },
                          {
                            value: "EUR",
                            label: "EUR",
                          },
                        ],
                      },
                      {
                        type: "field",
                        name: "iban",
                        label: "IBAN",
                        span: 9,
                        item: {
                          type: "text",
                          placeholder: "iban",
                          format: {
                            onChange: "iban",
                            onSubmit: "removeSpaces",
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
        {
          type: "yStack",
          gap: 4,
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  fieldType: "array",
                  name: "contacts",
                  label: "Contacts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "contactinfo",
                    //label: "Hesap Numarası",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "name",
                        label: "Name",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "name",
                          format: {
                            onChange: "removeExtraSpaces",
                            onSubmit: "trim",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "phone",
                        label: "Phone",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "phone",
                          format: {
                            onChange: "phoneNumberWithCountryCode",
                            onSubmit: "dbPhoneNumber",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^\+\d{1,3} \d{3,14}$/,
                            message: "Telefon numarası geçersiz",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "email",
                        label: "E-mail",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "email",
                          format: {
                            onChange: "trim",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                            message: "E-posta adresi geçersiz",
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const PackagesAction: React.FC<any> = ({ record }) => {
  const [visible, setVisible] = React.useState(false);
  const [__id, set__id] = React.useState<undefined | string>();

  return (
    <>
      <TableActionButton
        icon={<TbPackage />}
        popover={{
          content: "Packages",
          placement: "top",
        }}
        onClick={() => setVisible(true)}
        className="bg-pink-500 hover:bg-pink-600"
      />
      <Drawer
        open={visible}
        width={Math.min(window.document.body.clientWidth * 0.9, 860)}
        //bodyStyle={{ paddingBottom: 16 }}
        onClose={() => setVisible(false)}
        title="Packages of Hotel"
        style={{
          backgroundColor: "#f0f2f5",
        }}
      >
        <div className="flex flex-col justify-between items-start h-full w-full pb-4">
          <div className="w-full h-48 px-4 py-2 flex flex-col space-y-1">
            <div className="grid grid-cols-8 border-b-2 border-blue-300">
              <div className="col-span-2">Code</div>
              <div className="col-span-5">Name</div>
              <div className="col-span-1">Actions</div>
            </div>
            {record.packages.map((item: any) => {
              return (
                <div className="grid grid-cols-8 border-b">
                  <div className="col-span-2">{item.code}</div>
                  <div className="col-span-5">{item.name}</div>
                  <div className="col-span-1">
                    <div className="flex flex-row justify-center items-center space-x-2">
                      <TbEdit
                        className="text-yellow-500 text-lg cursor-pointer"
                        onClick={() => {
                          set__id(item._id);
                        }}
                      />
                      <FaTrash
                        className="text-red-400 text-lg cursor-pointer"
                        onClick={() => {
                          console.log(item._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="w-full overflow-y-scroll"
            style={{
              maxHeight: "calc(100vh - 350px)",
            }}
          >
            {(__id || true) &&
              PageGenerator(
                {
                  type: "form",
                  requestType: "create",
                  items: [
                    {
                      type: "grid",
                      cols: 12,
                      gap: 2,
                      items: [
                        {
                          type: "field",
                          name: "code",
                          label: "Code",
                          span: 2,
                          item: {
                            type: "text",
                            placeholder: "code",
                            format: {
                              onChange: "trim",
                            },
                          },
                          validations: {
                            required: true,
                          },
                        },
                        {
                          type: "field",
                          name: "capacity",
                          label: "Capacity",
                          span: 1,
                          item: {
                            type: "number",
                            placeholder: "0",
                            format: {
                              step: 1,
                            },
                            limitations: {
                              min: 1,
                              max: 8,
                            },
                          },
                          validations: {
                            required: true,
                          },
                        },
                        {
                          type: "field",
                          name: "name",
                          label: "Name",
                          span: 9,
                          item: {
                            type: "text",
                            placeholder: "name",
                            format: {
                              onChange: "removeExtraSpaces",
                              onSubmit: "trim",
                            },
                          },
                          validations: {
                            required: true,
                          },
                        },
                        {
                          type: "field",
                          name: "description",
                          label: "Description",
                          span: 12,
                          item: {
                            type: "text",
                            placeholder: "description",
                            format: {
                              onChange: "removeExtraSpaces",
                              onSubmit: "trim",
                            },
                          },
                        },
                        {
                          type: "field",
                          fieldType: "array",
                          name: "periods",
                          label: "Periods",
                          span: 12,
                          item: {
                            type: "field",
                            name: "period",
                            fieldType: "object",
                            //compact: true,
                            span: 12,
                            items: [
                              {
                                type: "field",
                                name: "range",
                                label: "Range",
                                span: 4,
                                item: {
                                  type: "DateRange",
                                  placeholder: "end date",
                                  format: {
                                    onChange: "date",
                                  },
                                },
                              },
                              {
                                type: "field",
                                fieldType: "object",
                                name: "price",
                                //label: "Price",
                                //compact: true,
                                span: 8,
                                items: [
                                  {
                                    type: "field",
                                    name: "currency",
                                    label: "Currency",
                                    validations: {
                                      required: true,
                                    },
                                    span: 3,
                                    item: {
                                      type: "select",
                                      placeholder: "Select currency",
                                      mode: "default",
                                      sort: "desc",
                                    },
                                    options: [
                                      {
                                        value: "TRY",
                                        label: "TRY",
                                      },
                                      {
                                        value: "USD",
                                        label: "USD",
                                      },
                                      {
                                        value: "EUR",
                                        label: "EUR",
                                      },
                                    ],
                                  },
                                  {
                                    type: "field",
                                    name: "price",
                                    label: "Price",
                                    span: 4,
                                    item: {
                                      type: "number",
                                      placeholder: "0",
                                      format: {
                                        step: 0.01,
                                      },
                                      limitations: {
                                        min: 0,
                                      },
                                    },
                                  },
                                  {
                                    type: "field",
                                    name: "vat",
                                    label: "VAT",
                                    span: 3,
                                    item: {
                                      type: "number",
                                      placeholder: "0",
                                      format: {
                                        step: 1,
                                      },
                                      limitations: {
                                        min: 0,
                                        max: 100,
                                      },
                                    },
                                  },
                                  {
                                    type: "field",
                                    name: "vatIncluded",
                                    label: "VAT Included",
                                    span: 2,
                                    item: {
                                      type: "switch",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "button",
                          inputType: "submit",
                          value: "Submit",
                          span: 12,
                        },
                      ],
                    },
                  ],
                },
                {
                  collection: `hotels/${record._id}/packages`,
                  invalidation: "hotels",
                  updateId: __id,
                  size: "small",
                },
              )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export class HotelController extends StandartCollectionController {
  constructor() {
    super("Hotel", "hotels", {
      columns,
      form,
      customActions: [PackagesAction],
    });
  }
}
