import React, { useEffect } from "react";
import { useFormik } from "formik";
import { PageGenerator } from "../../../app/uiFunctions/PageGenerator";
import {
  getFormData,
  getFormValidations,
} from "../../../app/uiFunctions/getFormData";
import { Col, Space } from "antd";
import { validate as validateFunction } from "../../../utils/validations";
import cn from "classnames";
import classGenerator from "../../../app/uiFunctions/classGenerator";

interface FormProps {
  form: any;
  onChange: (e: any) => void;
  context: any;
  value?: any;
}

export const ObjectInput: React.FC<FormProps> = ({
  form,
  onChange,
  context,
  value,
}) => {
  const validate = (values: any) => {
    return validateFunction(values, getFormValidations(form?.items));
  };

  const formik = useFormik({
    initialValues: value || getFormData(form?.items),
    onSubmit: () => {},
    validate,
  });

  useEffect(() => {
    onChange({
      target: {
        name: form.name,
        value: formik.values,
      },
    });
  }, [formik.values]);

  const gap = classGenerator.gap(context?.parent?.gap);

  if (!form.compact) {
    return (
      <div
        className={cn("w-full grid", gap)}
        style={{
          gridTemplateColumns: `repeat(${context?.parent?.cols || 12}, 1fr)`,
        }}
      >
        {form.items.map((item: any, index: number) => {
          return PageGenerator(item, {
            ...context,
            formik,
            key: index,
          });
        })}
      </div>
    );
  }
  return (
    <Space.Compact
      block
      className={cn("w-full grid")}
      style={{
        gridTemplateColumns: `repeat(${context?.parent?.cols || 12}, 1fr)`,
      }}
      size={context?.inputSize}
    >
      {form.items.map((item: any, index: number) => {
        return PageGenerator(item, {
          ...context,
          formik,
          key: index,
        });
      })}
    </Space.Compact>
  );
};
