import { column } from "..";
import BasicAssetLayout from "../../Layouts/BasicAssetLayout";
import { CollectionController } from "./AbstractCollectionController";
import { ICollectionController } from "./ICollectionController";

export class BasicCollectionController
  extends CollectionController
  implements ICollectionController
{
  protected columns?: column[];
  protected form?: any;
  constructor(title: string, collection: string, options?: any) {
    super(title, collection);
    this.list = BasicAssetLayout({
      title: this.collection,
      collection: this.collection,
      columns: options?.columns,
      form: options?.form,
    })!;
  }
}
