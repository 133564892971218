import React from "react";
import {
  Select,
  Input,
  Button,
  DatePicker,
  Modal,
  Table,
  message,
  InputNumber,
} from "antd";
import axios from "axios";
import { TableTransfer } from "../components";
import { formatTime } from "../utils/formatTime";
import { AiOutlineCar } from "react-icons/ai";
import { MdPersonAddAlt } from "react-icons/md";
import moment from "moment";
import { NumberInput, SelectInput } from "../components/shared/components";
import { PageGenerator } from "../app/uiFunctions/PageGenerator";
import { epochToGMT3Date } from "../utils/gmt3DateConverter";

const columns = [
  {
    dataIndex: ["transferExpectations", "date"],
    title: "Date",
    render(text: any, record: any) {
      return moment.unix(text).format("DD/MM/YYYY");
    },
  },
  {
    dataIndex: ["transferExpectations", "time"],
    title: "Time",
    render(text: any, record: any) {
      return formatTime(text);
    },
  },
  {
    dataIndex: ["flightInformations", "flightNumber"],
    title: "Flight",
    render(text: string, record: any) {
      console.log("record", record);
      return `${
        record.flightInformations.flightCarrier +
        record.flightInformations.flightNumber
      }`;
    },
  },
  {
    dataIndex: ["operation", "name"],
    title: "Operation",
  },
  {
    dataIndex: ["transferExpectations", "type"],
    title: "Type",
  },
  {
    title: "Count",
    render(text: any, record: any) {
      return record.voyagers.length;
    },
  },
];

const CreateTransfer = () => {
  const [cruises, setCruises] = React.useState([]);
  const [pages, setPages] = React.useState([]);
  const [operations, setOperations] = React.useState([]);
  const [cruise, setCruise] = React.useState("");
  const [page, setPage] = React.useState("");
  const [operation, setOperation] = React.useState("");
  const [vehicleModal, setVehicleModal] = React.useState(false);
  const [personalModal, setPersonalModal] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [voyagers, setVoyagers] = React.useState<any>([]);
  const [vehicles, setVehicles] = React.useState<any>([]);
  const [personals, setPersonals] = React.useState<any>([]);
  const [guides, setGuides] = React.useState<any>([]);
  const [pickUpTimePrefixInMinutes, setPickUpTimePrefixInMinutes] =
    React.useState<any>(0);
  const [type, setType] = React.useState<any>(null);

  const [data, setData] = React.useState<any>([]);

  const importHandler = async (
    start: any,
    end: any,
    type: any,
    location: any
  ) => {
    console.log("import");
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/voyagers/groups?date=${start},${end}${
          type ? `&type=${type}` : ""
        }${location ? `&location=${location}` : ""}`
      )
      .then((res) => {
        const values = res.data.map((i: any) => {
          return {
            //random key
            key: Math.random().toString(36).substr(2, 9),
            ...i,
          };
        });
        setData(values);
      });
  };

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/cruises`).then((res) => {
      const values = res.data.data.map((cruise: any) => {
        return {
          value: cruise._id,
          label: cruise.code,
        };
      });
      setCruises(values);
    });
  }, []);

  React.useEffect(() => {
    if (cruise !== "") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/sheets/${cruise}`)
        .then((res) => {
          const values = res.data.map((page: any) => {
            return {
              value: page._id,
              label: page.code,
            };
          });
          setPages(values);
        });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/operations?query=cruise='${cruise}'`
        )
        .then((res) => {
          const values = res.data.data.map((operation: any) => {
            return {
              value: operation._id,
              label: operation.name.split(" | ").slice(1).join(" | "),
            };
          });
          setOperations(values);
        });
    }
  }, [cruise]);

  const createHandler = async () => {
    const voyagers: string[] = [];

    selectedRows.forEach((i: any) => {
      i.voyagers.forEach((j: string) => voyagers.push(j));
    });

    console.log("selectedRows", selectedRows);
    const transferData = {
      operation: selectedRows[0].operation._id,
      flightNumber:
        selectedRows[0].flightInformations.flightCarrier +
        selectedRows[0].flightInformations.flightNumber,
      voyagers: voyagers,
      transferType: selectedRows[0].transferExpectations.type,
      transferTime: selectedRows[0].transferExpectations.time,
      vehicles: vehicles.map((i: any) => {
        return {
          vehicle: i._id,
          firm: i.vehicleFirm,
          plate: i.plate,
        };
      }),
      personals: personals.map((i: any) => i._id),
      guides: guides.map((i: any) => i._id),
      pickUpTimePrefixInMinutes: pickUpTimePrefixInMinutes,
    };

    // ant message
    message.loading({
      content: "Creating Transfer...",
      key: "createTransfer",
      duration: 2,
    });

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/transfers`, transferData)
        .then((res) => {
          console.log(res);
        });

      // ant message
      message.success({
        content: "Transfer Created",
        key: "createTransfer",
        duration: 2,
      });

      // clear
      setData(data.filter((i: any) => !selectedRows.includes(i)));
      setVehicles([]);
      setPersonals([]);
      setGuides([]);
      setVoyagers([]);
      setSelectedRows([]);
    } catch (error) {
      // ant message
      message.error({
        content: "Transfer Creation Failed",
        key: "createTransfer",
        duration: 2,
      });
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="bg-slate-400">
          {PageGenerator({
            type: "form",
            onSubmit: async (data: any, formik: any) => {
              //console.log(data);
              setType(data.type);
              await importHandler(
                epochToGMT3Date(data.range.start),
                epochToGMT3Date(data.range.end),
                data.type,
                data.location
              );
            },
            items: [
              {
                type: "xStack",
                className: "py-2 px-4",
                breakpoint: "md",
                gap: 4,
                align: "start",
                items: [
                  {
                    type: "field",
                    name: "type",
                    span: 2,
                    item: {
                      type: "select",
                      placeholder: "type",
                      format: {
                        onChange: "trim",
                      },
                    },
                    options: [
                      {
                        value: "Post",
                        label: "Post",
                      },
                      {
                        value: "Pre",
                        label: "Pre",
                      },
                      {
                        value: "hotel",
                        label: "Return",
                      },
                    ],
                  },
                  {
                    type: "field",
                    name: "location",
                    span: 5,
                    item: {
                      type: "select",
                      placeholder: "Select Location",
                      search: true,
                      mode: "default",
                      clear: true,
                    },
                    remote: {
                      url: `${process.env.REACT_APP_API_URL}/api/{{$type}}s?limit=5&search={{value}}`,
                      value: "_id",
                    },
                    dependents: ["type"],
                  },
                  {
                    type: "field",
                    name: "range",
                    span: 6,
                    item: {
                      type: "DateRange",
                      placeholder: "end date",
                      format: {
                        onChange: "date",
                      },
                    },
                  },
                  {
                    type: "button",
                    inputType: "submit",
                    value: "Import",
                    span: 3,
                  },
                ],
              },
            ],
          })}
        </div>
        ;
        {/* <div className="flex space-x-2 px-4 py-2 bg-slate-400">
          <Select
            options={cruises}
            className="w-48"
            onChange={(val) => setCruise(val)}
            placeholder="Select Cruise"
          />
          <Select
            options={pages}
            className="w-48"
            onChange={(val) => setPage(val)}
            placeholder="Select Page"
          />
          <Select
            options={operations}
            className="flex-grow"
            onChange={(val) => setOperation(val)}
            placeholder="Select Operation"
          />
          <DatePicker className="w-48" />
          <Button
            className="w-24"
            type="primary"
            onClick={() => {
              //open external link
              window.open(
                `https://its.uzmanapi.com/api/voyagers/export?cruise=${cruise}&sheet=${page}`,
                "_blank",
              );
            }}
          >
            Export
          </Button>
          <Button
            className="w-24"
            type="primary"
            onClick={() => importHandler()}
          >
            Import
          </Button>
          <Button className="w-24" type="primary" danger>
            Reset
          </Button>
        </div> */}
        <div className="flex space-x-2">
          <div className="flex-grow">
            {/* <TableTransfer
              leftColumns={[
                {
                  dataIndex: ["transferExpectations", "date"],
                  title: "Date",
                },
                {
                  dataIndex: ["transferExpectations", "time"],
                  title: "Time",
                },
                {
                  dataIndex: ["flightInformations", "flightNumber"],
                  title: "Flight",
                },
                {
                  dataIndex: ["operation", "name"],
                  title: "Operation",
                },
                {
                  dataIndex: ["transferExpectations", "type"],
                  title: "Type",
                },
              ]}
              rightColumns={[
                {
                  dataIndex: ["transferExpectations", "date"],
                  title: "Date",
                },
                {
                  dataIndex: ["transferExpectations", "time"],
                  title: "Time",
                },
                {
                  dataIndex: ["flightInformations", "flightNumber"],
                  title: "Flight",
                },
                {
                  dataIndex: ["operation", "name"],
                  title: "Operation",
                },
                {
                  dataIndex: ["transferExpectations", "type"],
                  title: "Type",
                },
              ]}
              dataSource={data}
              onTargetKeysChange={(targetKeys: any) => {
                console.log("targetKeys: ", targetKeys);
              }}
            /> */}
            <Table
              columns={columns}
              dataSource={data}
              rowSelection={{
                onChange: (
                  selectedRowKeys: React.Key[],
                  selectedRows: any[]
                ) => {
                  setSelectedRows(selectedRows);
                },
              }}
            />
          </div>
          <div className="w-96 flex flex-col space-y-4">
            <div className="w-full bg-slate-100 rounded-md border-2">
              <div className="w-full h-full flex items-start justify-center space-y-1 text-slate-500 pt-4 flex-col">
                <div className="flex items-center justify-center w-full space-x-2">
                  <AiOutlineCar className="text-2xl" />
                  <p>Select Veichles</p>
                </div>
                <div
                  className="flex-grow w-full flex flex-col space-y-1 items-center"
                  style={{
                    minHeight: "120px",
                  }}
                >
                  {vehicles.map((vehicle: any) => (
                    <div className="flex space-x-2">
                      <span>
                        {vehicle.firm} | {vehicle.name} | {vehicle.plate}
                      </span>
                      <span
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          setVehicles(
                            vehicles.filter((v: any) => v.ouid !== vehicle.ouid)
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex w-full">
                  {PageGenerator({
                    type: "form",
                    name: "form",
                    onSubmit: (values: any, formik: any) => {
                      setVehicles([
                        ...vehicles,
                        {
                          name: JSON.parse(values.vehicle).name,
                          firm: JSON.parse(values.vehicleFirm).name,
                          plate: values.plate,
                          _id: JSON.parse(values.vehicle)._id,
                          vehicleFirm: JSON.parse(values.vehicleFirm)._id,
                          ouid: Math.random(),
                        },
                      ]);
                      formik.resetForm();
                    },
                    items: [
                      {
                        type: "xStack",
                        items: [
                          {
                            type: "field",
                            name: "vehicleFirm",
                            span: 1,
                            item: {
                              type: "select",
                              placeholder: "Select Firm",
                              search: true,
                              mode: "default",
                              clear: true,
                              selfReturn: true,
                            },
                            remote: {
                              url: `${process.env.REACT_APP_API_URL}/api/carfirms?limit=5&search={{value}}`,
                              value: "_id",
                            },
                          },
                          {
                            type: "field",
                            name: "vehicle",
                            span: 1,
                            item: {
                              type: "select",
                              placeholder: "Select Vehicle",
                              search: true,
                              mode: "default",
                              clear: true,
                              selfReturn: true,
                            },
                            remote: {
                              url: `${process.env.REACT_APP_API_URL}/api/cartypes?limit=5&search={{value}}`,
                              value: "_id",
                            },
                          },
                          {
                            type: "field",
                            name: "plate",
                            span: 4,
                            item: {
                              type: "text",
                              placeholder: "Plate",
                            },
                          },
                          {
                            type: "button",
                            inputType: "submit",
                            value: "Add",
                            span: 1,
                          },
                        ],
                      },
                    ],
                  })}
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-md border-2">
              <div className="w-full h-full flex items-start justify-center space-y-1 text-slate-500 pt-4 flex-col">
                <div className="flex items-center justify-center w-full space-x-2">
                  <MdPersonAddAlt className="text-2xl" />
                  <p>Select Guides</p>
                </div>
                <div
                  className="flex-grow w-full flex flex-col space-y-1 items-center"
                  style={{
                    minHeight: "120px",
                  }}
                >
                  {guides.map((guide: any) => (
                    <div className="flex space-x-2">
                      <span>{guide.name}</span>
                      <span
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          setGuides(
                            guides.filter((v: any) => v.ouid !== guide.ouid)
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex w-full">
                  {PageGenerator({
                    type: "form",
                    name: "form",
                    onSubmit: (values: any, formik: any) => {
                      setGuides([
                        ...guides,
                        {
                          name: JSON.parse(values.guide).name,
                          _id: JSON.parse(values.guide)._id,
                          ouid: Math.random(),
                        },
                      ]);
                      formik.resetForm();
                    },
                    items: [
                      {
                        type: "xStack",
                        items: [
                          {
                            type: "field",
                            name: "guide",
                            span: 8,
                            item: {
                              type: "select",
                              placeholder: "Select Guide",
                              search: true,
                              mode: "default",
                              clear: true,
                              selfReturn: true,
                            },
                            remote: {
                              url: `${process.env.REACT_APP_API_URL}/api/guides?limit=5&search={{value}}`,
                              value: "_id",
                            },
                          },
                          {
                            type: "button",
                            inputType: "submit",
                            value: "Add",
                            span: 1,
                          },
                        ],
                      },
                    ],
                  })}
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-md border-2">
              <div className="w-full h-full flex items-start justify-center space-y-1 text-slate-500 pt-4 flex-col">
                <div className="flex items-center justify-center w-full space-x-2">
                  <MdPersonAddAlt className="text-2xl" />
                  <p>Select Personals</p>
                </div>
                <div
                  className="flex-grow w-full flex flex-col items-center"
                  style={{
                    minHeight: "120px",
                  }}
                >
                  {personals.map((personal: any) => (
                    <div className="flex space-x-2">
                      <span>{personal.name}</span>
                      <span
                        className="text-red-400 cursor-pointer"
                        onClick={() => {
                          setPersonals(
                            personals.filter(
                              (v: any) => v.ouid !== personal.ouid
                            )
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex w-full">
                  {PageGenerator({
                    type: "form",
                    name: "form",
                    onSubmit: (values: any, formik: any) => {
                      setPersonals([
                        ...personals,
                        {
                          name: JSON.parse(values.personal).name,
                          _id: JSON.parse(values.personal)._id,
                          ouid: Math.random(),
                        },
                      ]);
                      formik.resetForm();
                    },
                    items: [
                      {
                        type: "xStack",
                        items: [
                          {
                            type: "field",
                            name: "personal",
                            span: 8,
                            item: {
                              type: "select",
                              placeholder: "Select Personal",
                              search: true,
                              mode: "default",
                              clear: true,
                              selfReturn: true,
                            },
                            remote: {
                              url: `${process.env.REACT_APP_API_URL}/api/personals?limit=5&search={{value}}`,
                              value: "_id",
                            },
                          },
                          {
                            type: "button",
                            inputType: "submit",
                            value: "Add",
                            span: 1,
                          },
                        ],
                      },
                    ],
                  })}
                </div>
              </div>
            </div>

            {type === "Post" && (
              <div className="w-full bg-slate-100 rounded-md border-2 flex flex-col space-y-1">
                <span>Pick Up Time Prefix In Minutes</span>
                <InputNumber
                  className="w-full"
                  value={pickUpTimePrefixInMinutes}
                  onChange={(e: any) => setPickUpTimePrefixInMinutes(e)}
                  step={30}
                />
              </div>
            )}

            <Button
              className="h-12"
              block
              type="primary"
              onClick={createHandler}
            >
              Create Transfer
            </Button>
          </div>
        </div>
        ; ;
      </div>
      <Modal
        title="Select Vehicle"
        open={vehicleModal}
        onOk={() => setVehicleModal(false)}
        onCancel={() => setVehicleModal(false)}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
      <Modal
        title="Select Personal"
        open={personalModal}
        onOk={() => setPersonalModal(false)}
        onCancel={() => setPersonalModal(false)}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};

export default CreateTransfer;
