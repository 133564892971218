import React from "react";
import { Typography } from "antd";
import { IParentComponent } from "../../types";
import { AiOutlineCopy } from "react-icons/ai";

export const CopyableText: IParentComponent = ({ children, className }) => {
  return (
    <Typography.Text
      copyable={{
        icon: [<AiOutlineCopy className="-mb-0.5" />],
      }}
      className={className}
    >
      {children}
    </Typography.Text>
  );
};
