import { FC, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { deleteRecord } from "../../app";
import { AiOutlineDelete } from "react-icons/ai";
import { TableActionButton } from "..";

export const DeleteAction: FC<any> = ({
  onError,
  collection,
  id,
  onSuccess,
}) => {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);

  // Mutations
  const mutation = useMutation(() => deleteRecord(collection, id), {
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([collection]);
      onSuccess
        ? onSuccess()
        : message.success({
            content: "Record Successfully Deleted",
            duration: 2,
            key: `${collection}-${id}`,
          });
    },
    onError: (e: any) => {
      onError
        ? onError(e)
        : message.error({
            content: e.response.data.message,
            duration: 2,
            key: `${collection}-${id}`,
          });
    },
  });

  const deleteHandler = () => {
    setLoading(true);
    message.loading({
      content: "Loading...",
      duration: 2,
      key: `${collection}-${id}`,
    });
    mutation.mutate();
  };

  return (
    <TableActionButton
      popover={{
        content: "Delete Record",
        placement: "top",
      }}
      icon={<AiOutlineDelete />}
      onClick={deleteHandler}
      popconfirm={{
        title: "Are you sure you want to delete this record?",
      }}
      className="bg-red-500 hover:bg-red-600"
      disabled={loading}
    />
  );
};
