import React from "react";
import { Button as AntButton } from "antd";

interface ButtonProps {
  loading?: boolean;
  value: string;
  disabled?: boolean;
  type?: "submit" | "button";
  span?: number;
  onClick?: () => void;
}
export const Button: React.FC<ButtonProps> = ({
  loading = false,
  value,
  disabled,
  type = "button",
  onClick,
  span,
}) => {
  return (
    <AntButton
      className={`${span ? `col-span-${span}` : ""}`}
      htmlType={type}
      type="primary"
      loading={loading}
      disabled={disabled}
      block
      onClick={onClick}
    >
      {value}
    </AntButton>
  );
};
