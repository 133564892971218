import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "operation Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/operations/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [],
};

export class OperationController extends StandartCollectionController {
  constructor() {
    super("Operation", "operations", {
      columns,
    });
  }
}
