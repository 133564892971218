import { Popconfirm, Popover } from "antd";
import { ITableActionButtonComponent } from "../../types";

export const TableActionButton: ITableActionButtonComponent = ({
  popover,
  icon,
  onClick,
  popconfirm,
  className = "bg-blue-500 hover:bg-blue-600",
  style,
  disabled,
}) => {
  return (
    <Popover placement={popover.placement || "right"} content={popover.content}>
      {popconfirm ? (
        <Popconfirm
          title={popconfirm.title || "Are you sure?"}
          okText={popconfirm.okText || "Yes"}
          cancelText={popconfirm.cancelText || "No"}
          onConfirm={onClick}
        >
          <span
            style={style}
            className={`text-white text-lg w-7 h-7 font-bold flex items-center justify-center rounded-full cursor-pointer transition-all ${className} ${
              disabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {icon}
          </span>
        </Popconfirm>
      ) : (
        <span
          onClick={onClick}
          style={style}
          className={`text-white text-lg w-7 h-7 font-bold flex items-center justify-center rounded-full cursor-pointer transition-all ${className} ${
            disabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {icon}
        </span>
      )}
    </Popover>
  );
};
