import React, { useState, useEffect } from "react";
import { Table, PageLayout, TableActionButton } from "../components";
import { Button, Drawer, Input, Space } from "antd";
import { TbPackage } from "react-icons/tb";

const StandartAssetLayout: React.FC<any> = ({
  search,
  collection,
  title,
  columns,
  customActions,
  disableNewButton,
}) => {
  const navigate = PageLayout.useNavigate();

  return (
    <PageLayout
      title={title}
      breadcrumb={[{ title: "App" }, { title: title, link: `/${collection}` }]}
      actions={
        disableNewButton && (
          <Button
            type="primary"
            onClick={() => navigate(`/${collection}/create`)}
          >
            Add a New {title}
          </Button>
        )
      }
    >
      {search && (
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            navigate(`/${collection}?${search}=${e.target[0].value}`);
          }}
          className="w-full"
        >
          <Space.Compact block>
            <Input placeholder={search} prefix={<TbPackage />} />
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Space.Compact>
        </form>
      )}
      <Table
        collection={collection}
        columns={columns}
        customActions={customActions}
        deleteAction={disableNewButton}
        editAction={disableNewButton}
      />
    </PageLayout>
  );
};

export default StandartAssetLayout;
