import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { ITextArea } from "../../../types/uiComponentTypes";
import formatText from "../../../utils/textFormatter";
import cn from "classnames";

export const TextArea: ITextArea = ({
  className,
  style,
  name,
  placeholder,
  value,
  hasError,
  onChange,
  onBlur,
  format,
  rows = 4,
  disabled,
}) => {
  const [valueField, setValueField] = useState(
    format?.onChange ? formatText(format.onChange, value) : value,
  );

  useEffect(() => {
    if (valueField === "" || valueField === undefined) {
      onChange({ target: { name, value: undefined } });
    } else {
      if (format?.onSubmit) {
        onChange({
          target: {
            name,
            value: formatText(format.onSubmit, valueField),
          },
        });
      } else {
        onChange({
          target: {
            name,
            value: valueField,
          },
        });
      }
    }
  }, [valueField]);

  useEffect(() => {
    setValueField(
      format?.onChange ? formatText(format.onChange, value) : value,
    );
  }, [value]);

  return (
    <Input.TextArea
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={(e) => {
        if (format?.onChange) {
          setValueField(
            formatText(format.onChange, e.target.value, valueField) || "",
          );
        } else {
          setValueField(e.target.value);
        }
      }}
      onBlur={onBlur}
      value={valueField}
      style={style}
      status={hasError ? "error" : ""}
      className={cn("w-full", className)}
      rows={rows}
      disabled={disabled}
      // showCount
      // maxLength={100}
    />
  );
};
