import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { ITextInput } from "../../../types/uiComponentTypes";
import formatText from "../../../utils/textFormatter";
import cn from "classnames";

export const TextInput: ITextInput = ({
  className,
  style,
  name,
  placeholder,
  value,
  hasError,
  onChange,
  type = "text",
  format,
  size,
  onBlur,
  disabled,
}) => {
  const [valueField, setValueField] = useState(
    format?.onChange ? formatText(format.onChange, value) : value,
  );

  useEffect(() => {
    if (valueField === "" || valueField === undefined) {
      onChange({ target: { name, value: undefined } });
    } else {
      if (format?.onSubmit) {
        onChange({
          target: {
            name,
            value: formatText(format.onSubmit, valueField),
          },
        });
      } else {
        onChange({
          target: {
            name,
            value: valueField,
          },
        });
      }
    }
  }, [valueField]);

  useEffect(() => {
    setValueField(
      format?.onChange ? formatText(format.onChange, value) : value,
    );
  }, [value]);

  return (
    <Input
      name={name}
      id={name}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => {
        if (format?.onChange) {
          setValueField(
            formatText(format.onChange, e.target.value, valueField) || "",
          );
        } else {
          setValueField(e.target.value);
        }
      }}
      onBlur={onBlur}
      value={valueField}
      style={style}
      status={hasError ? "error" : ""}
      className={cn("w-full", className)}
      size={size}
    />
  );
};
