import React, { useState, useEffect, useCallback } from "react";
import { Input, Space } from "antd";
import { INumberInput } from "../../../types/uiComponentTypes";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import cn from "classnames";

function add(a: number, b: number, precision?: number) {
  var x = Math.pow(10, precision || 0);
  return (Math.round(a * x) + Math.round(b * x)) / x;
}

function subtract(a: number, b: number, precision?: number) {
  var x = Math.pow(10, precision || 0);
  return (Math.round(a * x) - Math.round(b * x)) / x;
}

function stringToNumber(value: string, separator: string) {
  if (!value) return undefined;
  let [integer, decimal] = value?.split(separator);
  integer = integer?.replace(/[^0-9]/g, "");
  decimal = decimal?.replace(/[^0-9]/g, "");

  const isNegative = value?.startsWith("-");

  let val = "";
  if (integer && decimal) {
    val = `${integer}.${decimal}`;
  } else if (integer && !decimal) {
    val = `${integer}`;
  } else if (!integer && decimal) {
    val = `.${decimal}`;
  } else {
    val = "";
  }

  return isNegative ? -Number(val) : Number(val);
}

function addDigitSeparator(value: string, digitSeparator: string) {
  if (digitSeparator === "") return value;
  return value?.replace(/\B(?=(\d{3})+(?!\d))/g, digitSeparator);
}

interface FormatFunction {
  (
    value: string,
    separator?: string,
    digitSeparator?: string,
    precision?: number,
  ): string;
}

const formatInput: FormatFunction = (
  value,
  separator = ".",
  digitSeparator = "",
  precision,
) => {
  if (!value) return "";
  if (separator === digitSeparator) return "Error";

  if (value === `-${separator}`) return "-";

  const isNegative = value.startsWith("-");

  let val;

  let [integer, decimal] = value.split(separator);

  const hasDecimal = decimal !== undefined;
  const hasInteger = integer !== undefined;

  integer = integer?.replace(/[^0-9]/g, "");
  decimal = decimal?.replace(/[^0-9]/g, "");

  integer = addDigitSeparator(integer, digitSeparator);

  if (hasInteger && hasDecimal) {
    val = `${integer}${separator}${decimal}`;
  } else if (hasInteger && !hasDecimal) {
    val = `${integer}`;
  } else if (!hasInteger && hasDecimal) {
    val = `${separator}${decimal}`;
  } else {
    val = "";
  }

  if (isNegative) val = `-${val}`;
  return val;
};

export const NumberInput: INumberInput = ({
  className,
  style,
  name,
  placeholder,
  value,
  hasError,
  onChange,
  onBlur,
  type = "text",
  format,
  limitations,
  size,
  disabled,
}) => {
  const [focused, setFocused] = useState(false);
  const [valueState, setValueState] = useState(value);
  const [numberValue, setNumberValue] = useState<null | number | undefined>(
    undefined,
  );

  const separator = format?.separator || ".";
  const digitSeparator = format?.digitSeparator || "";
  const step = format?.step || 1;
  const min =
    limitations?.min !== undefined ? limitations.min : -999999999999999;
  const max =
    limitations?.max !== undefined ? limitations.max : 999999999999999;
  const precision = format?.precision || 6;
  const prefix = format?.prefix || "";
  const suffix = format?.suffix || "";

  useEffect(() => {
    if (value !== undefined) {
      let val = formatInput(
        value?.toString().replace(new RegExp(`\\.`, "g"), separator),
        separator,
        digitSeparator,
        precision,
      );
      setValueState(val);
    } else {
      setValueState("");
    }
  }, [value, separator, digitSeparator, precision]);

  useEffect(() => {
    const val =
      valueState === "" ||
      valueState === "-" ||
      valueState === "." ||
      valueState === ","
        ? null
        : valueState === undefined
        ? undefined
        : valueState === "-0" || valueState === "-0."
        ? 0
        : typeof valueState === "number"
        ? valueState < min
          ? min
          : valueState > max
          ? max
          : valueState
        : Number(stringToNumber(valueState, separator)) < min
        ? min
        : Number(stringToNumber(valueState, separator));

    onChange({
      target: {
        name: name,
        value: val,
      },
    });
  }, [valueState, name, min, max, separator]);

  // useEffect(() => {
  //   onChange({
  //     target: {
  //       name: name,
  //       value: numberValue,
  //     },
  //   });
  // }, [numberValue]);

  const incrementCallback = useCallback(() => {
    if (valueState === "" || valueState === "-") {
      setValueState("0");
    } else {
      const val = add(
        Number(stringToNumber(valueState, separator)),
        step,
        precision,
      );
      setValueState(formatInput(val.toString(), separator, digitSeparator));
    }
  }, [valueState, separator, digitSeparator, step, precision]);

  const decrementCallback = useCallback(() => {
    if (valueState === "" || valueState === "-") {
      setValueState("0");
    } else {
      const val = subtract(
        Number(stringToNumber(valueState, separator)),
        step,
        precision,
      );

      setValueState(formatInput(val.toString(), separator, digitSeparator));
    }
  }, [valueState, separator, digitSeparator, step, precision]);

  const handleInputChange = useCallback(
    (e: any) => {
      const pressedKey = e.nativeEvent.data;
      const cursorPositionStart = e.target.selectionStart;
      const cursorPositionEnd = e.target.selectionEnd;
      let val = e.target.value;
      if (pressedKey === "," || pressedKey === ".") {
        if (cursorPositionStart === cursorPositionEnd) {
          val = `${val.slice(0, cursorPositionStart)}${separator}${val.slice(
            cursorPositionEnd,
          )}`;
        } else {
          val = `${val.slice(0, cursorPositionStart)}${separator}${val.slice(
            cursorPositionEnd,
          )}`;
        }
      }
      setValueState(formatInput(val, separator, digitSeparator, precision));
    },
    [separator, digitSeparator, precision],
  );

  const handleInputBlur = useCallback(
    (e: any) => {
      setFocused(false);
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  return (
    <Space.Compact
      className={cn(
        "w-full items-stratch border rounded-md bg-white transition-all",
        {
          "border-red-500 hover:border-red-300": hasError,
          "hover:border-blue-400": !hasError,
        },
        {
          "shadow-md shadow-red-100": focused,
        },
        { "bg-gray-100": disabled },
      )}
      style={{
        height:
          size === "large" ? "39px" : size === "small" ? "23.5px" : "31px",
      }}
    >
      <Input
        autoComplete="off"
        name={name}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "ArrowUp") {
            setNumberValue((prev) => {
              if (prev === null || prev === undefined) return 0;
              return add(prev, step, precision);
            });
          }
          if (e.key === "ArrowDown") {
            setNumberValue((prev) => {
              if (prev === null || prev === undefined) return 0;
              return subtract(prev, step, precision);
            });
          }
          if (e.key === "Enter") {
          }
        }}
        value={`${prefix}${valueState ? valueState : ""}${suffix}`}
        style={style}
        status={hasError ? "error" : ""}
        className={cn("w-full", className)}
        size={size}
        bordered={false}
        onFocus={() => setFocused(true)}
        onBlur={handleInputBlur}
      />
      <div className="flex flex-col h-full">
        <span
          className={cn(
            "w-5 h-full flex items-center justify-center bg-slate-100 hover:bg-slate-200 text-slate-500 border border-t-0 border-r-0 rounded-tr-md cursor-pointer box-content transition-all",
          )}
          onClick={incrementCallback}
        >
          <MdKeyboardArrowUp
            style={{
              fontSize: size === "small" ? "10px" : "14px",
            }}
          />
        </span>
        <span
          className={cn(
            "w-5 h-full flex items-center justify-center bg-slate-100 hover:bg-slate-200 text-slate-500 border border-b-0 border-r-0 rounded-br-md cursor-pointer box-content transition-all",
          )}
          onClick={decrementCallback}
        >
          <MdKeyboardArrowDown
            style={{
              fontSize: size === "small" ? "10px" : "14px",
            }}
          />
        </span>
      </div>
    </Space.Compact>
  );
};
