import React from "react";
import { IRow } from "../../../types/uiComponentTypes";
import cn from "classnames";
import { PageGenerator } from "../../../app/uiFunctions/PageGenerator";
import classGenerator from "../../../app/uiFunctions/classGenerator";

export const Row: IRow = ({
  gap = 0,
  align,
  justify,
  height,
  width,
  className,
  style,
  items,
  context,
  breakpoint,
}) => {
  return (
    <div
      className={cn(
        "flex flex-wrap md:flex-nowrap",
        {
          [`${breakpoint ? `space-x-0 ${breakpoint}:` : ""}space-x-${gap}`]:
            gap,
        },
        classGenerator.justify(justify),
        classGenerator.align(align),
        className,
      )}
      style={{
        ...style,
        ...width,
        ...height,
      }}
    >
      {items?.map((item: any, index: number) => {
        return PageGenerator(
          {
            ...item,
            key: index,
          },
          context,
        );
      })}
    </div>
  );
};

const spacehandler =
  "space-x-0 space-x-2 space-x-4 space-x-8 md:space-x-2 md:space-x-4 md:space-x-8";
