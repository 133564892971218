import moment from "moment-timezone";

const removeSpaces = (value: string) => {
  if (!value) return value;
  return value.replace(/\s+/g, "");
};

const formatIBAN = (input: string) => {
  let cleanedInput = input.replace(/\s+/g, "").toUpperCase();

  if (cleanedInput.length === 1 && cleanedInput !== "T") {
    cleanedInput = "TR" + cleanedInput;
  } else if (cleanedInput.length > 1 && !cleanedInput.startsWith("TR")) {
    cleanedInput = "TR" + cleanedInput.slice(1);
  }

  cleanedInput =
    cleanedInput.slice(0, 2) + cleanedInput.slice(2).replace(/\D+/g, "");

  const groups = [
    cleanedInput.slice(0, 4), // TR
    cleanedInput.slice(4, 8),
    cleanedInput.slice(8, 12),
    cleanedInput.slice(12, 16),
    cleanedInput.slice(16, 20),
    cleanedInput.slice(20, 24),
    cleanedInput.slice(24, 26),
  ];

  return groups.filter((group) => group.length > 0).join(" ");
};

const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6,
  )}-${currentValue.slice(6, 10)}`;
};

const formatCountryCallingCode = (value: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength === 0) return "";
  if (currentValue === "0") return "+90 ";
  return `+${currentValue.slice(0, 3)}`;
};

const removeExtraSpaces = (value: string) => {
  console.log(value);
  if (!value) return value;
  if (value === " ") return "";
  value = value.toString();
  return value.replace(/\s+/g, " ");
};

const formatTurkishPrice = (value: string) => {
  // Numara değilse veya NaN ise, geri döndür
  const num = Number(value.toString().replace(/\./g, "")).toFixed(2);

  // Numarayı ondalık kısma ve tam kısma ayır
  let [wholeNumber, decimal] = num.toString().split(".");

  console.log(wholeNumber, decimal);

  // Tam kısmı Türk tipi fiyat gösterimine göre biçimlendir
  wholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  console.log(wholeNumber, decimal);

  // Tam kısım ve ondalık kısmı birleştir ve geri döndür
  return `${wholeNumber},${decimal}`;
};

const formatCommaSeperated = (value: string) => {
  if (!value) return value;
  return value.toString().replace(/\./g, ",");
};

const excelSerialDateToISODate = (serialDate: any) => {
  // Excel'deki seri numarasını JavaScript tarihine dönüştürme
  const date = new Date(Math.round((serialDate - 25569) * 86400 * 1000));

  // İstanbul saat dilimine göre düzeltme (GMT+3)
  const offset = 3 * 60 * 60 * 1000; // 3 saatlik dilim farkı milisaniye cinsinden
  const istanbulDate = new Date(date.getTime() - offset);

  // İstanbul tarihini milisaniye cinsinden döndürme
  console.log(istanbulDate.getTime());
  return istanbulDate.getTime() / 1000;
};

const formatText = (type: string, value: string, oldValue?: string) => {
  if (!value) return value;
  switch (type) {
    case "toString":
      return value.toString();
    case "phoneNumberWithCountryCode":
      if (oldValue?.length && value.length < oldValue.length) {
        if (oldValue?.slice(-1) === " ") {
          return value;
        }
        if (value === "+") {
          return "";
        }
      }

      const code = formatCountryCallingCode(value?.split(" ")[0]);
      const number = formatPhoneNumber(value?.split(" ").slice(1).join(" "));
      if (!value?.includes(" ")) {
        return `${code}`;
      }
      return `${code} ${number}`;
    case "countryCallingCode":
      return formatCountryCallingCode(value);
    case "phoneNumber":
      return formatPhoneNumber(value);
    case "dbPhoneNumber":
      if (value === "") return undefined;
      let [countryCode, phonecode, phonenum] = value?.split(" ");
      phonecode = phonecode?.replace("(", "").replace(")", "");
      phonenum = phonenum?.replace("-", "");
      return `${countryCode ? countryCode : ""} ${phonecode ? phonecode : ""}${
        phonenum ? phonenum : ""
      }`;
    case "removeExtraSpaces":
      return removeExtraSpaces(value);
    case "trim":
      return value.trim();
    case "commaSeperated":
      return formatCommaSeperated(value);
    case "turkishPrice":
      return formatTurkishPrice(value);
    case "iban":
      return formatIBAN(value);
    case "removeSpaces":
      return removeSpaces(value);
    case "excelSerialDateToISODate":
      return excelSerialDateToISODate(value);
    default:
      return value;
  }
};

export default formatText;
