// Bu fonksiyon, anahtarlar dizisini kullanarak nesneye erişimi sağlar
export function accessNestedObject(
  obj: Record<string, any>,
  keys: string | string[],
): any {
  const keyArray = Array.isArray(keys) ? keys : [keys];

  return keyArray.reduce(
    (acc: any, key: string) =>
      acc && acc[key] !== undefined ? acc[key] : undefined,
    obj,
  );
}
