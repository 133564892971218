import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";
import moment from "moment";
import { formatTime } from "../../utils/formatTime";

const columns: column[] = [
  {
    title: "Invoice",
    dataIndex: "invoice",
    key: "invoice",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/voyagers/${record._id}`} className="font-semibold">
        {record.invoice}
      </Link>
    ),
  },
  {
    title: "Voyagers Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/voyagers/${record._id}`} className="font-semibold">
        {record.personalInformations.name}
      </Link>
    ),
  },
  {
    title: "Cruise",
    dataIndex: "cruise",
    key: "cruise",
    dataType: "string",
    render: (_id, record) => record.cruise?.code,
  },
  {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    dataType: "string",
    render: (_id, record) =>
      record.operation?.name.split(" | ").splice(1).join(" | "),
  },
  {
    title: "Transfer Time",
    dataIndex: "transferTime",
    key: "transferTime",
    dataType: "string",
    render: (_id, record) =>
      moment.unix(record.transferExpectations.date).format("DD/MM/YYYY") +
      " " +
      formatTime(record.transferExpectations.time),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "invoice",
                  label: "Invoice",
                  span: 6,
                  validations: {
                    required: true,
                  },
                  item: {
                    type: "text",
                    placeholder: "invoice",
                    format: {
                      onChange: "removeExtraSpaces",
                      onSubmit: "trim",
                    },
                  },
                },
                {
                  type: "field",
                  name: "voyageType",
                  label: "Type",
                  span: 6,
                  validations: {
                    required: true,
                  },
                  item: {
                    type: "radio",
                    mode: "button",
                    options: [
                      {
                        label: "Post",
                        value: "Post",
                      },
                      {
                        label: "Pre",
                        value: "Pre",
                      },
                    ],
                  },
                },
                {
                  type: "field",
                  name: "personalInformations",
                  //label: "Hesap Numarası",
                  fieldType: "object",
                  compact: true,
                  span: 12,
                  items: [
                    {
                      type: "field",
                      name: "name",
                      label: "Name",
                      validations: {
                        required: true,
                      },
                      span: 6,
                      item: {
                        type: "text",
                        placeholder: "name",
                        format: {
                          onChange: "removeExtraSpaces",
                          onSubmit: "trim",
                        },
                      },
                    },
                    {
                      type: "field",
                      name: "passportNumber",
                      label: "Passport",
                      span: 4,
                      item: {
                        type: "text",
                        placeholder: "passport",
                      },
                    },
                    {
                      type: "field",
                      name: "nationality",
                      label: "Nationality",
                      span: 2,
                      item: {
                        type: "text",
                        placeholder: "nationality",
                      },
                    },
                  ],
                },

                {
                  type: "field",
                  name: "cruise",
                  label: "Cruise",
                  validations: {
                    required: true,
                  },
                  span: 6,
                  item: {
                    type: "select",
                    placeholder: "Select cruise",
                    search: true,
                    mode: "default",
                  },
                  remote: {
                    url: `${process.env.REACT_APP_API_URL}/api/cruises`,
                    fetchOnMount: true,
                    value: "_id",
                    label: "code",
                  },
                },
                {
                  type: "field",
                  name: "package",
                  label: "Package",
                  validations: {
                    required: true,
                  },
                  span: 6,
                  item: {
                    type: "select",
                    placeholder: "Select cruise",
                    search: true,
                    mode: "default",
                  },
                  remote: {
                    url: `${process.env.REACT_APP_API_URL}/api/packages`,
                    fetchOnMount: true,
                    value: "_id",
                    label: "name",
                  },
                },

                {
                  type: "field",
                  name: "arrive",
                  label: "Arrive Date (dont fill on update)",
                  span: 6,
                  item: {
                    type: "DatePicker",
                  },
                },

                {
                  type: "field",
                  name: "depart",
                  label: "Depart Date (dont fill on update)",
                  span: 6,
                  item: {
                    type: "DatePicker",
                  },
                },

                {
                  type: "field",
                  name: "flightInformations",
                  //label: "Hesap Numarası",
                  fieldType: "object",
                  compact: true,
                  span: 12,
                  items: [
                    {
                      type: "field",
                      name: "flightCarrier",
                      label: "Carrier",
                      span: 2,
                      item: {
                        type: "text",
                        placeholder: "TK",
                        format: {
                          onChange: "trim",
                        },
                      },
                    },
                    {
                      type: "field",
                      name: "flightNumber",
                      label: "Flight Number",
                      span: 4,
                      item: {
                        type: "text",
                        placeholder: "flight number",
                      },
                    },
                    {
                      type: "field",
                      name: "flightDate",
                      label: "Flight Date",
                      span: 3,
                      item: {
                        type: "DatePicker",
                      },
                    },
                    {
                      type: "field",
                      name: "flightTime",
                      label: "Flight Time",
                      span: 3,
                      item: {
                        type: "number",
                        placeholder: "flight number",
                        limitations: {
                          min: 0,
                          max: 2400,
                        },
                        format: {
                          step: 100,
                        },
                      },
                    },
                  ],
                },

                {
                  type: "field",
                  name: "transferExpectations",
                  //label: "Hesap Numarası",
                  fieldType: "object",
                  compact: true,
                  span: 12,
                  items: [
                    {
                      type: "field",
                      name: "type",
                      label: "Transfer Type",
                      span: 3,
                      item: {
                        type: "select",
                        placeholder: "STD",
                      },
                      options: [
                        { value: "STD", label: "Standart" },
                        { value: "No Transfer", label: "No Transfer" },
                        { value: "PVT VAN", label: "PVT VAN" },
                        { value: "PVT CAR", label: "PVT CAR" },
                        { value: "PVT Only", label: "PVT Only" },
                        { value: "Unknown", label: "Unknown" },
                        {
                          value: "Need Manuel Assistance",
                          label: "Need Manuel Assistance",
                        },
                      ],
                    },
                    {
                      type: "field",
                      name: "date",
                      label: "Transfer Date",
                      span: 6,
                      item: {
                        type: "DatePicker",
                      },
                    },
                    {
                      type: "field",
                      name: "time",
                      label: "Transfer Time",
                      span: 3,
                      item: {
                        type: "number",
                        placeholder: "flight number",
                        limitations: {
                          min: 0,
                          max: 2400,
                        },
                        format: {
                          step: 100,
                        },
                      },
                    },
                  ],
                },

                {
                  type: "field",
                  name: "comments",
                  label: "Comments",
                  span: 12,
                  item: {
                    type: "textarea",
                    placeholder: "comments",
                    rows: 2,
                    format: {
                      onChange: "removeExtraSpaces",
                      onSubmit: "trim",
                    },
                  },
                },

                {
                  type: "field",
                  name: "page",
                  label: "Page",
                  value: "manualUpload",
                  span: 12,
                  item: {
                    type: "input",
                    placeholder: "page",
                    disabled: true,
                  },
                },

                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
        {
          type: "yStack",
          gap: 4,
          span: 3,
          items: [],
        },
      ],
    },
  ],
};

const importOptions: any = {
  structure: [
    {
      column: "D",
      key: "transferType",
      input: {
        type: "select",
        options: [
          { value: "STD", label: "Standart" },
          { value: "No Transfer", label: "No Transfer" },
          { value: "PVT VAN", label: "PVT VAN" },
          { value: "PVT CAR", label: "PVT CAR" },
          { value: "PVT Only", label: "PVT Only" },
          { value: "Unknown", label: "Unknown" },
          { value: "Need Manuel Assistance", label: "Need Manuel Assistance" },
        ],
      },
    },
    {
      column: "AD",
      key: "cruise",
      input: {
        type: "select",
        fetch: `${process.env.REACT_APP_API_URL}/api/cruises`,
      },
    },
    {
      column: "F",
      key: "invoice",
      format: "toString",
    },
    {
      column: "G",
      key: "firstName",
    },
    {
      column: "H",
      key: "lastName",
    },
    {
      column: "I",
      key: "title",
    },
    {
      column: "J",
      key: "sex",
    },
    {
      column: "K",
      key: "arrive",
      input: {
        type: "date",
      },
      format: "excelSerialDateToISODate",
    },
    {
      column: "L",
      key: "depart",
      input: {
        type: "date",
      },
      format: "excelSerialDateToISODate",
    },
    {
      column: "M",
      key: "flightCarrier",
    },
    {
      column: "N",
      key: "flightNumber",
    },
    {
      column: "O",
      key: "flightDate",
      input: {
        type: "date",
      },
      format: "excelSerialDateToISODate",
    },
    {
      column: "P",
      key: "flightTime",
    },
    {
      column: "Q",
      key: "accommodation",
    },
    {
      column: "R",
      key: "stateRoom",
      format: "removeExtraSpaces",
    },
    {
      column: "S",
      key: "pickupTime",
      format: "onlyNumbers",
    },
    {
      column: "T",
      key: "comments",
    },
    {
      column: "U",
      key: "nationality",
    },
    {
      column: "V",
      key: "passportNumber",
      format: "toString",
    },
    {
      column: "W",
      key: "birthDate",
      input: {
        type: "date",
      },
      format: "excelSerialDateToISODate",
    },
    {
      column: "B",
      key: "package",
      input: {
        type: "select",
        fetch: `${process.env.REACT_APP_API_URL}/api/packages`,
      },
    },
    {
      column: "AE",
      key: "page",
      input: {
        type: "readonly",
      },
    },
  ],
};

export class VoyagerController extends StandartCollectionController {
  constructor() {
    super("Voyager", "voyagers", {
      columns,
      form,
      import: importOptions,
      search: "invoice",
    });
  }
}
