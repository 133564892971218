import { BreadCrumb } from "..";
import { IPageLayoutComponent } from "../../types";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PageLayout: IPageLayoutComponent = ({
  breadcrumb = [],
  children,
  actions,
  style,
  className,
  title,
}) => {
  // set page title
  document.title = title;

  return (
    <div
      className={`flex h-full w-full flex-col space-y-4 ${className}`}
      style={style}
    >
      <div className="flex items-center justify-between">
        <BreadCrumb breadcrumb={breadcrumb} />
        {actions}
      </div>
      <div className="flex-grow overflow-y-scroll">{children}</div>
    </div>
  );
};

PageLayout.useNavigate = useNavigate;
