import React from "react";
import { Button, DatePicker, Field, Form, Input } from "../../components/";
import { createRecord } from "../functions/createRecord";
import cn from "classnames";
import {
  Row,
  Column,
  Space,
  Grid,
  TextInput,
  TextArea,
  NumberInput,
  SelectInput,
  Checkbox,
  RadioGroup,
  Switch,
  TimePicker,
  ObjectInput,
  ArrayInput,
  DateRange,
} from "../../components/shared/components";

const widthCalculator = (parent?: any, width?: any, span?: any) => {
  if (width)
    if (parent?.type === "xStack") return { width, flexShrink: 0 };
    else return { width };
  if (parent?.type === "xStack") {
    if (parent.pieces)
      return { width: `${(1 / parent.pieces) * (span ? span : 1) * 100}%` };
    if (span) return { width: `${span * 100}%` };
  }
  if (parent?.type === "grid") {
    if (typeof span === "object") {
      if (span.coords) {
        return {
          gridColumnStart: span.coords.col.start,
          gridRowStart: span.coords.row.start,
          gridColumnEnd: span.coords.col.end + 1,
          gridRowEnd: span.coords.row.end + 1,
        };
      }

      return {
        gridColumn: `span ${span.col} / span ${span.col}`,
        gridRow: `span ${span.row} / span ${span.row}`,
      };
    }
    if (span) return { gridColumn: `span ${span} / span ${span}` };
    else return { gridColumn: `1 / -1` };
  }
  return { width: "100%" };
};

const heightCalculator = (parent?: any, height?: any, span?: any) => {
  if (height)
    if (parent?.type === "yStack") return { height, flexShrink: 0 };
    else return { height };
  if (parent?.type === "yStack") {
    if (parent.pieces)
      return { height: `${(1 / parent.pieces) * (span ? span : 1) * 100}%` };
    if (span) return { height: `${span * 100}%` };
  }
  if (parent?.type === "grid" && parent.rows)
    return { gridRow: `span ${span}` };
  return { height: "auto" };
};

export const PageGenerator: any = (component: any, context?: any) => {
  // if (component.type === "container") {
  //   return (
  //     <div className="mx-4" {...props}>
  //       {component.items?.map((item: any, index: number) => {
  //         return PageGenerator(item, {
  //           ...props,
  //           key: index,
  //         });
  //       })}
  //     </div>
  //   );
  // }
  if (component.type === "xStack") {
    return (
      <Row
        {...component}
        width={widthCalculator(context.parent, component.width, component.span)}
        height={heightCalculator(
          context.parent,
          component.height,
          component.span,
        )}
        key={component.key}
        context={{
          ...context,
          parent: {
            type: component.type,
            pieces: component.pieces,
          },
        }}
      />
    );
  }
  if (component.type === "yStack") {
    return (
      <Column
        {...component}
        width={widthCalculator(context.parent, component.width, component.span)}
        height={heightCalculator(
          context.parent,
          component.height,
          component.span,
        )}
        key={component.key}
        context={{
          ...context,
          parent: {
            type: component.type,
            pieces: component.pieces,
          },
        }}
      />
    );
  }
  if (component.type === "space") {
    return <Space key={component.key} />;
  }
  if (component.type === "grid") {
    return (
      <Grid
        {...component}
        width={widthCalculator(context.parent, component.width, component.span)}
        height={heightCalculator(
          context.parent,
          component.height,
          component.span,
        )}
        key={component.key}
        context={{
          ...context,
          parent: {
            type: component.type,
            cols: component.cols,
            gap: component.gap,
          },
        }}
      />
    );
  }
  if (component.type === "form") {
    return (
      <Form
        form={component}
        {...context}
        type={component.requestType}
        onSubmit={component.onSubmit}
        onChange={component.onChange}
        onSuccess={component.onSuccess}
        inputSize={component.inputSize}
      />
    );
  }
  if (component.type === "field") {
    return (
      <Field
        name={component.name}
        type="field"
        label={component.label}
        error={
          context.formik.touched[component.name] &&
          context.formik.errors[component.name]
        }
        required={component.validations?.required}
        width={widthCalculator(context.parent, component.width, component.span)}
        height={heightCalculator(
          context.parent,
          component.height,
          component.span
        )}
        style={component.style}
        className={component.className}
        key={component.name}
      >
        {component.item?.type === "textarea" ? (
          <TextArea
            name={component.name}
            value={context?.formik?.values[component.name]}
            rows={component.item?.rows}
            onChange={context?.formik?.handleChange}
            format={component.item?.format}
            type={component.item?.type}
            placeholder={component.item?.placeholder}
            hasError={
              context.formik.touched[component.name] &&
              context.formik.errors[component.name]?.length > 0
            }
            style={component.item.style}
            className={component.item.className}
            onBlur={context?.formik?.handleBlur}
            disabled={context?.isLoading}
          />
        ) : component.item === null ? (
          <input
            name={component.name}
            value={context?.formik?.values[component.name]}
            hidden
          />
        ) : component.item?.type === "DateRange" ? (
          <DateRange
            name={component.name}
            onChange={context?.formik?.handleChange}
            context={context}
            value={context?.formik?.values[component.name]}
            format={component.item?.format?.date}
          />
        ) : component.item?.type === "DatePicker" ? (
          <DatePicker
            type={component.item?.type}
            name={component.name}
            onChange={context?.formik?.handleChange}
            context={context}
            value={context?.formik?.values[component.name]}
            format={component.item?.format?.date}
          />
        ) : component.fieldType === "object" ? (
          <ObjectInput
            onChange={context?.formik?.handleChange}
            form={component}
            context={context}
            value={context?.formik?.values[component.name]}
          />
        ) : component.fieldType === "array" ? (
          <ArrayInput
            onChange={context?.formik?.handleChange}
            form={component}
            context={context}
            value={context?.formik?.values[component.name]}
          />
        ) : component.item?.type === "time" ? (
          <TimePicker
            name={component.name}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            type={component.item?.type}
            style={component.item.style}
            className={component.item.className}
            mode={component.item?.mode}
            size={context?.inputSize}
          />
        ) : component.item?.type === "switch" ? (
          <Switch
            name={component.name}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            type={component.item?.type}
            style={component.item.style}
            className={component.item.className}
            options={component.item?.options}
            mode={component.item?.mode}
            sort={component.item?.sort}
            remote={component.remote}
            cols={component.item?.cols}
            size={context?.inputSize}
          />
        ) : component.item?.type === "radio" ? (
          <RadioGroup
            name={component.name}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            type={component.item?.type}
            style={component.item.style}
            className={component.item.className}
            options={component.item?.options}
            mode={component.item?.mode}
            sort={component.item?.sort}
            remote={component.remote}
            cols={component.item?.cols}
            size={context?.inputSize}
          />
        ) : component.item?.type === "checkbox" ? (
          <Checkbox
            name={component.name}
            option={component.item?.option}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            type={component.item?.type}
            style={component.item.style}
            className={component.item.className}
            options={component.item?.options}
            sort={component.item?.sort}
            remote={component.remote}
            cols={component.item?.cols}
          />
        ) : component.item?.type === "select" ? (
          <SelectInput
            name={component.name}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            type={component.item?.type}
            placeholder={component.item?.placeholder}
            hasError={
              context.formik.touched[component.name] &&
              context.formik.errors[component.name]?.length > 0
            }
            style={component.item.style}
            className={component.item.className}
            size={context?.inputSize}
            options={component.options}
            search={component.item?.search}
            mode={component.item?.mode}
            sort={component.item?.sort}
            remote={component.remote}
            clear={component.item?.clear}
            onBlur={context?.formik?.handleBlur}
            disabled={context?.isLoading}
            dependent={{
              // foreach dependent in component.dependents [dependent.name] = formik.values[dependent.name]
              ...component.dependents?.reduce(
                (acc: any, curr: any) => ({
                  ...acc,
                  [curr]: context?.formik?.values[curr],
                }),
                {}
              ),
            }}
            selfReturn={component.item?.selfReturn}
          />
        ) : component.item?.type === "number" ? (
          <NumberInput
            name={component.name}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            format={component.item?.format}
            type={component.item?.type}
            placeholder={component.item?.placeholder}
            hasError={
              context.formik.touched[component.name] &&
              context.formik.errors[component.name]?.length > 0
            }
            style={component.item.style}
            className={component.item.className}
            limitations={component.item?.limitations}
            size={context?.inputSize}
            onBlur={context?.formik?.handleBlur}
            disabled={context?.isLoading}
          />
        ) : (
          <TextInput
            name={component.name}
            value={context?.formik?.values[component.name]}
            onChange={context?.formik?.handleChange}
            format={component.item?.format}
            type={component.item?.type}
            placeholder={component.item?.placeholder}
            hasError={
              context.formik.touched[component.name] &&
              context.formik.errors[component.name]?.length > 0
            }
            style={component.item?.style}
            className={component.item?.className}
            size={context?.inputSize}
            onBlur={context?.formik?.handleBlur}
            disabled={context?.isLoading || component.item?.disabled}
          />
        )}
      </Field>
    );

    // return (
    //   <Field
    //     width={widthCalculator(context.parent, component.width, component.span)}
    //     height={heightCalculator(
    //       context.parent,
    //       component.height,
    //       component.span,
    //     )}
    //     name={component.name}
    //     type={component.inputType}
    //     label={component.label}
    //     placeholder={component.placeholder}
    //     value={context?.formik?.values[component.name]}
    //     onChange={context?.formik?.handleChange}
    //     validations={component.validations}
    //     item={component}
    //     span={component.span}
    //     rows={component.rows}
    //     key={component.name}
    //     options={component.options}
    //     {...context}
    //     context={{
    //       ...context,
    //       parent: {
    //         type: component.type,
    //       },
    //     }}
    //   />
    // );
  }
  if (component.type === "button") {
    return (
      <Button
        type={component.inputType}
        value={component.value}
        key={component.value}
        span={component.span}
        disabled={context?.isLoading}
        {...context}
      />
    );
  }
  if (component.type === "text") {
    return (
      <p key={component.key} className={component.className}>
        {component.value}
      </p>
    );
  }
};
