import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/personalfirms/${record._id}`} className="font-semibold">
        {record._id}
      </Link>
    ),
  },
  {
    title: "Firm Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/personalfirms/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 2,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Firm Name",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "firm name",
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                  validations: {
                    required: {
                      message: "Firm name is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "address",
                  label: "Firm Address",
                  span: 12,
                  item: {
                    type: "textarea",
                    placeholder: "firm address",
                    rows: 2,
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                },
                {
                  type: "field",
                  fieldType: "array",
                  name: "accounts",
                  label: "Accounts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "accountNumber",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "currency",
                        label: "Currency",
                        span: 3,
                        item: {
                          type: "select",
                          placeholder: "USD",
                          mode: "default",
                        },
                        options: [
                          {
                            value: "TRY",
                            label: "TRY",
                          },
                          {
                            value: "USD",
                            label: "USD",
                          },
                          {
                            value: "EUR",
                            label: "EUR",
                          },
                        ],
                      },
                      {
                        type: "field",
                        name: "iban",
                        label: "IBAN",
                        span: 9,
                        item: {
                          type: "text",
                          placeholder: "iban",
                          format: {
                            onChange: "iban",
                            onSubmit: "removeSpaces",
                          },
                        },
                      },
                    ],
                  },
                },

                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
        {
          type: "yStack",
          gap: 4,
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  fieldType: "array",
                  name: "contacts",
                  label: "Contacts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "contactinfo",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "name",
                        label: "Name",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "name",
                          format: {
                            onChange: "removeExtraSpaces",
                            onSubmit: "trim",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "phone",
                        label: "Phone",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "phone",
                          format: {
                            onChange: "phoneNumberWithCountryCode",
                            onSubmit: "dbPhoneNumber",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^\+\d{1,3} \d{3,14}$/,
                            message: "Telefon numarası geçersiz",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "email",
                        label: "E-mail",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "email",
                          format: {
                            onChange: "trim",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                            message: "E-posta adresi geçersiz",
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class PersonalFirmController extends StandartCollectionController {
  constructor() {
    super("Firm", "personalfirms", {
      columns,
      form,
    });
  }
}
