export function formatTime(timeNumber: number | string) {
  if (typeof timeNumber === "string") {
    timeNumber = parseInt(timeNumber);
  }
  if (isNaN(timeNumber) || timeNumber === 0 || timeNumber === null) {
    return "--:--";
  }
  const hour = Math.floor(timeNumber / 100);
  const minute = timeNumber % 100;

  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;
}
