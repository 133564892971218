import React from "react";
import { PageLayout } from "../components";
import { Button } from "antd";
import { CreateForm } from "../components";
import { PageGenerator } from "../app/uiFunctions/PageGenerator";

const CreateReacordLayout: React.FC<any> = ({
  collection,
  title,
  form,
  formLayout,
  layout,
}) => {
  const navigate = PageLayout.useNavigate();
  return (
    <PageLayout
      title={title}
      breadcrumb={[
        { title: "App" },
        { title: title, link: `/${collection}` },
        { title: `Create ${collection}` },
      ]}
      actions={
        <Button type="primary" onClick={() => navigate(`/${collection}`)}>
          List {collection}
        </Button>
      }
    >
      {/* <CreateForm form={form} collection={collection} layout={formLayout} /> */}
      {PageGenerator(layout, { collection })}
    </PageLayout>
  );
};

export default CreateReacordLayout;
