import React from "react";
import { DatePicker as AntDate } from "antd";
import {
  epochToGMT3Date,
  gmt3DateToEpoch,
} from "../../../utils/gmt3DateConverter";

import dayjs from "dayjs";

export const DateRange: React.FC<any> = ({ value, onChange, name, format }) => {
  //const time: any = dayjs(value, "YYYY-MM-DDTHH:mm:ss.SSSZ");
  const systemDateFormat = "YYYY-MM-DD";

  return (
    <AntDate.RangePicker
      className="w-full"
      name={name}
      value={
        value?.start && [
          dayjs(epochToGMT3Date(value.start), systemDateFormat),
          dayjs(epochToGMT3Date(value.end), systemDateFormat),
        ]
      }
      format={format || systemDateFormat}
      onChange={(val: any) => {
        onChange &&
          onChange({
            target: {
              name: name,
              value: {
                start: gmt3DateToEpoch(dayjs(val[0]).format(systemDateFormat)),
                end: gmt3DateToEpoch(
                  dayjs(val[1]).format(systemDateFormat),
                  true,
                ),
              },
            },
          });
      }}
    />
  );
};
