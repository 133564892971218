export function getFormData(form: any) {
  return form?.reduce((acc: any, item: any) => {
    if (item.type === "button") {
      return acc;
    } else if (item.type === "field") {
      if (item.inputType === "object") {
        acc[item.name] = getFormData(item.items);
      } else {
        acc[item.name] = item.value;
      }
    } else {
      acc = { ...acc, ...getFormData(item.items) };
    }
    return acc;
  }, {});
}

export function getFormValidations(form: any) {
  return form?.reduce((acc: any, item: any) => {
    if (item.type === "button") {
      return acc;
    } else if (item.type === "field") {
      if (item.inputType === "object") {
        acc[item.name] = getFormValidations(item.items);
      } else {
        acc[item.name] = item.validations;
      }
    } else {
      acc = { ...acc, ...getFormValidations(item.items) };
    }
    return acc;
  }, {});
}
