import React from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { fetchLogin, resetPassword } from "../../app/api";

const LoginForm = ({ userLogin }: any) => {
  const onFinish = async (values: { email: any; password: any }) => {
    try {
      const data = await fetchLogin(values.email, values.password);
      console.log(data);
      userLogin(data);
    } catch (err: any) {
      console.log(err);
      message.error(err?.response?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishF = async (values: { email: any }) => {
    message.loading({
      content: "Loading...",
      key: "fg",
    });
    try {
      await resetPassword({ email: values.email });
      message.success({
        content: `${values.email} adresine sıfırlama e-postası gönderdik.`,
        key: "fg",
      });
      setForgotLayout(false);
    } catch (err: any) {
      message.error({
        content: err.response.data.message,
        key: "fg",
      });
    }
  };

  const onFinishFailedF = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [forgotLayout, setForgotLayout] = React.useState(false);

  if (!forgotLayout) {
    return (
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="px-8 py-4 bg-slate-50"
        style={{ minWidth: 400 }}
      >
        <Form.Item
          label="E-Posta"
          name="email"
          rules={[
            { required: true, message: "Lütfen e-posta adresinizi giriniz!" },
          ]}
          className="mb-3"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Şifre"
          name="password"
          rules={[{ required: true, message: "Lütfen şifrenizi giriniz!" }]}
          className="mb-3"
        >
          <Input.Password />
        </Form.Item>

        <div className="flex justify-between items-center mb-3">
          <Form.Item
            name="remember"
            valuePropName="checked"
            className="p-0 m-0"
          >
            <Checkbox>Beni hatırla</Checkbox>
          </Form.Item>
          <span
            className="cursor-pointer hover:text-blue-600"
            onClick={() => setForgotLayout(true)}
          >
            Şifremi Unuttum
          </span>
        </div>

        <Form.Item>
          <Button
            type="primary"
            className=" bg-blue-400"
            htmlType="submit"
            block
          >
            Giriş Yap
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    return (
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinishF}
        onFinishFailed={onFinishFailedF}
        layout="vertical"
        className="px-8 py-4 bg-slate-50"
        style={{ minWidth: 400 }}
      >
        <Form.Item
          label="E-Posta"
          name="email"
          rules={[
            { required: true, message: "Lütfen e-posta adresinizi giriniz!" },
          ]}
          className="mb-3"
        >
          <Input />
        </Form.Item>

        <div className="flex justify-between items-center mb-3">
          <span></span>
          <span
            className=" cursor-pointer hover:text-blue-600"
            onClick={() => setForgotLayout(false)}
          >
            Giriş Yap
          </span>
        </div>

        <Form.Item>
          <Button
            type="primary"
            className=" bg-blue-400"
            htmlType="submit"
            block
          >
            Şifremi Sıfırla
          </Button>
        </Form.Item>
      </Form>
    );
  }
};

export default LoginForm;
