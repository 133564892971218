import { BasicCollectionController, column } from "../../types";

const columns: column[] = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "number",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    dataType: "string",
  },
  {
    title: "Route",
    dataIndex: "locations",
    key: "_id",
    render: (id: string, record: any) => {
      console.log(record);
      const lts = record.locations.map((location: any) => {
        return location.location;
      });
      console.log(lts);
      return `${lts.join(" - ")}`;
    },
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "grid",
      space: 2,
      items: [
        {
          type: "field",
          name: "code",
          label: "Code",
          span: 4,
          item: {
            type: "text",
            placeholder: "code",
            format: {
              onChange: "trim",
            },
          },
          validations: {
            required: true,
          },
        },
        {
          type: "field",
          name: "description",
          label: "Description",
          span: 8,
          item: {
            type: "text",
            placeholder: "description",
            format: {
              onChange: "removeExtraSpaces",
              onsubmit: "trim",
            },
          },
        },
        {
          type: "field",
          fieldType: "array",
          name: "locations",
          label: "Locations",
          span: 12,
          item: {
            type: "field",
            name: "location",
            fieldType: "object",
            span: 12,
            items: [
              {
                type: "field",
                name: "order",
                label: "Order",
                span: 2,
                item: {
                  type: "number",
                  placeholder: "1",
                  format: {
                    step: 1,
                  },
                  limitations: {
                    min: 1,
                  },
                },
                validations: {
                  required: true,
                },
              },
              {
                type: "field",
                name: "location",
                label: "Location",
                span: 10,
                item: {
                  type: "select",
                  placeholder: "Select location",
                  mode: "default",
                },
                options: [
                  {
                    value: "Airport",
                    label: "Airport",
                  },
                  {
                    value: "Port",
                    label: "Port",
                  },
                  {
                    value: "Hotel",
                    label: "Hotel",
                  },
                  {
                    value: "Restaurant",
                    label: "Restaurant",
                  },
                ],
              },
            ],
          },
        },
        {
          type: "button",
          inputType: "submit",
          value: "Kaydet",
          span: 12,
        },
      ],
    },
  ],
};

export class RouteController extends BasicCollectionController {
  constructor() {
    super("Route", "routes", {
      columns,
      form,
    });
  }
}
