import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/guidefees/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Name",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "name",
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                  validations: {
                    required: {
                      message: "name is required",
                    },
                  },
                },
                {
                  type: "field",
                  fieldType: "object",
                  name: "fees",
                  label: "Fees",
                  span: 12,
                  items: [
                    {
                      type: "field",
                      fieldType: "array",
                      name: "transferFee",
                      label: "Transfer Fee",
                      span: 6,
                      item: {
                        type: "field",
                        name: "period",
                        fieldType: "object",
                        //compact: true,
                        span: 12,
                        items: [
                          {
                            type: "field",
                            name: "range",
                            label: "Range",
                            span: 4,
                            item: {
                              type: "DateRange",
                              placeholder: "end date",
                              format: {
                                onChange: "date",
                              },
                            },
                          },
                          {
                            type: "field",
                            fieldType: "object",
                            name: "price",
                            //label: "Price",
                            //compact: true,
                            span: 8,
                            items: [
                              {
                                type: "field",
                                name: "currency",
                                label: "Currency",
                                validations: {
                                  required: true,
                                },
                                span: 3,
                                item: {
                                  type: "select",
                                  placeholder: "Select currency",
                                  mode: "default",
                                  sort: "desc",
                                },
                                options: [
                                  {
                                    value: "TRY",
                                    label: "TRY",
                                  },
                                  {
                                    value: "USD",
                                    label: "USD",
                                  },
                                  {
                                    value: "EUR",
                                    label: "EUR",
                                  },
                                ],
                              },
                              {
                                type: "field",
                                name: "price",
                                label: "Price",
                                span: 4,
                                item: {
                                  type: "number",
                                  placeholder: "0",
                                  format: {
                                    step: 0.01,
                                  },
                                  limitations: {
                                    min: 0,
                                  },
                                },
                              },
                              {
                                type: "field",
                                name: "vat",
                                label: "VAT",
                                span: 3,
                                item: {
                                  type: "number",
                                  placeholder: "0",
                                  format: {
                                    step: 1,
                                  },
                                  limitations: {
                                    min: 0,
                                    max: 100,
                                  },
                                },
                              },
                              {
                                type: "field",
                                name: "vatIncluded",
                                label: "VAT Included",
                                span: 2,
                                item: {
                                  type: "switch",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      type: "field",
                      fieldType: "array",
                      name: "dailyFee",
                      label: "Daily Fee",
                      span: 6,
                      item: {
                        type: "field",
                        name: "period",
                        fieldType: "object",
                        //compact: true,
                        span: 12,
                        items: [
                          {
                            type: "field",
                            name: "range",
                            label: "Range",
                            span: 4,
                            item: {
                              type: "DateRange",
                              placeholder: "end date",
                              format: {
                                onChange: "date",
                              },
                            },
                          },
                          {
                            type: "field",
                            fieldType: "object",
                            name: "price",
                            //label: "Price",
                            //compact: true,
                            span: 8,
                            items: [
                              {
                                type: "field",
                                name: "currency",
                                label: "Currency",
                                validations: {
                                  required: true,
                                },
                                span: 3,
                                item: {
                                  type: "select",
                                  placeholder: "Select currency",
                                  mode: "default",
                                  sort: "desc",
                                },
                                options: [
                                  {
                                    value: "TRY",
                                    label: "TRY",
                                  },
                                  {
                                    value: "USD",
                                    label: "USD",
                                  },
                                  {
                                    value: "EUR",
                                    label: "EUR",
                                  },
                                ],
                              },
                              {
                                type: "field",
                                name: "price",
                                label: "Price",
                                span: 4,
                                item: {
                                  type: "number",
                                  placeholder: "0",
                                  format: {
                                    step: 0.01,
                                  },
                                  limitations: {
                                    min: 0,
                                  },
                                },
                              },
                              {
                                type: "field",
                                name: "vat",
                                label: "VAT",
                                span: 3,
                                item: {
                                  type: "number",
                                  placeholder: "0",
                                  format: {
                                    step: 1,
                                  },
                                  limitations: {
                                    min: 0,
                                    max: 100,
                                  },
                                },
                              },
                              {
                                type: "field",
                                name: "vatIncluded",
                                label: "VAT Included",
                                span: 2,
                                item: {
                                  type: "switch",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      type: "field",
                      fieldType: "array",
                      name: "packageTourFee",
                      label: "Package Tour Fee",
                      span: 6,
                      item: {
                        type: "field",
                        name: "period",
                        fieldType: "object",
                        //compact: true,
                        span: 12,
                        items: [
                          {
                            type: "field",
                            name: "range",
                            label: "Range",
                            span: 4,
                            item: {
                              type: "DateRange",
                              placeholder: "end date",
                              format: {
                                onChange: "date",
                              },
                            },
                          },
                          {
                            type: "field",
                            fieldType: "object",
                            name: "price",
                            //label: "Price",
                            //compact: true,
                            span: 8,
                            items: [
                              {
                                type: "field",
                                name: "currency",
                                label: "Currency",
                                validations: {
                                  required: true,
                                },
                                span: 3,
                                item: {
                                  type: "select",
                                  placeholder: "Select currency",
                                  mode: "default",
                                  sort: "desc",
                                },
                                options: [
                                  {
                                    value: "TRY",
                                    label: "TRY",
                                  },
                                  {
                                    value: "USD",
                                    label: "USD",
                                  },
                                  {
                                    value: "EUR",
                                    label: "EUR",
                                  },
                                ],
                              },
                              {
                                type: "field",
                                name: "price",
                                label: "Price",
                                span: 4,
                                item: {
                                  type: "number",
                                  placeholder: "0",
                                  format: {
                                    step: 0.01,
                                  },
                                  limitations: {
                                    min: 0,
                                  },
                                },
                              },
                              {
                                type: "field",
                                name: "vat",
                                label: "VAT",
                                span: 3,
                                item: {
                                  type: "number",
                                  placeholder: "0",
                                  format: {
                                    step: 1,
                                  },
                                  limitations: {
                                    min: 0,
                                    max: 100,
                                  },
                                },
                              },
                              {
                                type: "field",
                                name: "vatIncluded",
                                label: "VAT Included",
                                span: 2,
                                item: {
                                  type: "switch",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class GuideFeeController extends StandartCollectionController {
  constructor() {
    super("Guide Fee", "guidefees", {
      columns,
      form,
    });
  }
}
