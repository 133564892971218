import React, { useState, useEffect } from "react";
import { Radio, Row, Col } from "antd";
import { IRadioGroup } from "../../../types/uiComponentTypes";
import cn from "classnames";
import axios from "axios";
import { accessNestedObject } from "../../../utils/accessNestedObjects";

export const RadioGroup: IRadioGroup = ({
  name,
  value,
  onChange,
  options = [],
  cols = 1,
  remote = false,
  mode = "default",
  size,
}) => {
  const [data, setData] = useState<any>(options);

  const fetch = (callback: any) => {
    axios
      .get(remote?.url || "", {
        headers: {
          Authorization: remote?.token
            ? `Bearer ${localStorage.getItem(remote.token)}`
            : "",
        },
      })
      .then((response: any) => {
        callback(
          accessNestedObject(response.data, remote?.data || "data").map(
            (i: any) => {
              return {
                label: accessNestedObject(i, remote?.label || "name"),
                value: accessNestedObject(i, remote?.value || "_id"),
              };
            },
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (val: string) => {
    if (remote !== false) {
      fetch(setData);
    }
  };

  useEffect(() => {
    if (remote !== false) {
      handleSearch("");
    }
  }, []);

  return (
    <Radio.Group
      className={cn("w-full block")}
      onChange={(e) => {
        onChange({
          target: {
            name,
            value: e.target.value,
          },
        });
      }}
      value={value as any}
      buttonStyle="solid"
      size={size}
    >
      {mode === "default" ? (
        <Row>
          {data.map((item: any) => (
            <Col span={24 / cols} key={item.value}>
              <Radio value={item.value}>{item.label}</Radio>
            </Col>
          ))}
        </Row>
      ) : (
        data.map((item: any) => (
          <Radio.Button key={item.value} value={item.value}>
            {item.label}
          </Radio.Button>
        ))
      )}
    </Radio.Group>
  );
};
