import { CopyableText } from "../../components";
import { BasicCollectionController, column } from "../../types";

const columns: column[] = [
  {
    title: "Id",
    dataIndex: "_id",
    key: "_id",
    dataType: "string",
    render: (text: string) => {
      return <CopyableText>{text}</CopyableText>;
    },
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "number",
  },
  {
    title: "Client",
    dataIndex: ["client", "name"],
    key: ["client", "name"],
    dataType: "string",
    render: (text: string) => {
      return text?.capitalize();
    },
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "grid",
      space: 2,
      items: [
        {
          type: "field",
          name: "code",
          label: "Code",
          span: 12,
          item: {
            type: "text",
            placeholder: "code",
            format: {
              onChange: "trim",
            },
          },
          validations: {
            required: true,
          },
        },
        {
          type: "field",
          name: "client",
          label: "Client",
          validations: {
            required: true,
          },
          span: 12,
          item: {
            type: "select",
            placeholder: "Select client",
            search: true,
            mode: "default",
          },
          remote: {
            url: `${process.env.REACT_APP_API_URL}/api/clients?search={{value}}`,
            fetchOnMount: true,
            value: "_id",
          },
        },
        {
          type: "button",
          inputType: "submit",
          value: "Submit",
          span: 12,
        },
      ],
    },
  ],
};

export class CruiseController extends BasicCollectionController {
  constructor() {
    super("Cruise", "cruises", {
      columns,
      form,
    });
  }
}
