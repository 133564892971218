// stringExtensions.ts

// Öncelikle, String tipine yeni bir method ekleyeceğimizi TypeScript'e bildirin.
declare global {
  interface String {
    capitalize(): string;
  }
}

// Şimdi, yeni methodu String prototype'ına ekleyin.
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  //return this.charAt(0).toLocaleUpperCase("tr-TR") + this.slice(1);
  const words = this.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toLocaleUpperCase("tr-TR") + word.slice(1);
  });
  return capitalizedWords.join(" ");
};

// TypeScript modüllerinde yan etkili işlemler yaparken export etmek gereklidir.
export {};
