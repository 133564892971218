export function epochToGMT3Date(epoch: number) {
  const date = new Date(epoch * 1000);
  const offset = 3 * 60 * 60 * 1000;
  const istanbulDate = new Date(date.getTime() + offset);

  const dateString = istanbulDate.toISOString().slice(0, 10);
  return dateString;
}

export function gmt3DateToEpoch(dateString: string, isEndOfDate = false) {
  const date = new Date(dateString);
  const offset = 3 * 60 * 60 * 1000;

  const dateSpace = isEndOfDate ? 24 * 60 * 60 * 1000 - 1000 : 0;
  let epoch = (date.getTime() + dateSpace - offset) / 1000;

  return epoch;
}
