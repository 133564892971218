const justify: (arg0: string | undefined) => string = (justify) => {
  switch (justify) {
    case "center":
      return "justify-center";
    case "start":
      return "justify-start";
    case "end":
      return "justify-end";
    case "between":
      return "justify-between";
    case "around":
      return "justify-around";
    case "evenly":
      return "justify-evenly";
    default:
      return "";
  }
};

const align: (arg0: string | undefined) => string = (align) => {
  switch (align) {
    case "center":
      return "items-center";
    case "start":
      return "items-start";
    case "end":
      return "items-end";
    case "baseline":
      return "items-baseline";
    case "stretch":
      return "items-stretch";
    default:
      return "";
  }
};

const width: (arg0: string | undefined) => string = (width) => {
  switch (width) {
    case "full":
      return "w-full";
    case "auto":
      return "w-auto";
    case "screen":
      return "w-screen";
    case "w-1/12":
      return "w-1/12";
    case "w-2/12":
      return "w-2/12";
    case "w-3/12":
      return "w-3/12";
    case "w-4/12":
      return "w-4/12";
    case "w-5/12":
      return "w-5/12";
    case "w-6/12":
      return "w-6/12";
    case "w-7/12":
      return "w-7/12";
    case "w-8/12":
      return "w-8/12";
    case "w-9/12":
      return "w-9/12";
    case "w-10/12":
      return "w-10/12";
    case "w-11/12":
      return "w-11/12";
    case "w-1/6":
      return "w-1/6";
    case "w-2/6":
      return "w-2/6";
    case "w-3/6":
      return "w-3/6";
    case "w-4/6":
      return "w-4/6";
    case "w-5/6":
      return "w-5/6";
    case "w-1/5":
      return "w-1/5";
    case "w-2/5":
      return "w-2/5";
    case "w-3/5":
      return "w-3/5";
    case "w-4/5":
      return "w-4/5";
    case "w-1/4":
      return "w-1/4";
    case "w-2/4":
      return "w-2/4";
    case "w-3/4":
      return "w-3/4";
    case "w-1/3":
      return "w-1/3";
    case "w-2/3":
      return "w-2/3";
    case "w-1/2":
      return "w-1/2";
    case "w-1/1":
      return "w-1/1";
    default:
      return "";
  }
};

const gap: (arg0: number | undefined) => string = (gap) => {
  switch (gap) {
    case 0:
      return "gap-0";
    case 1:
      return "gap-1";
    case 2:
      return "gap-2";
    case 3:
      return "gap-3";
    case 4:
      return "gap-4";
    case 5:
      return "gap-5";
    case 6:
      return "gap-6";
    case 7:
      return "gap-7";
    case 8:
      return "gap-8";
    case 9:
      return "gap-9";
    case 10:
      return "gap-10";
    case 11:
      return "gap-11";
    case 12:
      return "gap-12";
    case 14:
      return "gap-14";
    case 16:
      return "gap-16";
    case 20:
      return "gap-20";
    case 24:
      return "gap-24";
    default:
      return "";
  }
};

const classGenerator = {
  justify,
  align,
  gap,
};

export default classGenerator;
