import { useState, createElement } from "react";

import { Avatar, Dropdown, Layout as AntLayout, Menu as AntMenu } from "antd";
import { IParentComponent } from "../types";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { BiUser, BiChevronDown, BiNotification } from "react-icons/bi";

import project from "../app/statics/project.json";
import { Menu } from "../app/statics/menu";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../helpers/AuthContext";

const { Header, Sider, Content, Footer } = AntLayout;

export const Layout: IParentComponent = ({ children }) => {
  const isCollapsed = localStorage.getItem("collapsed") === "true";

  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [largeScreen, setLargeScreen] = useState(false);

  const { user, logout } = useAuth();

  const navigate = useNavigate();

  return (
    <div className="flex w-screen h-screen overflow-hidden">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth={largeScreen ? 80 : 0}
        onBreakpoint={(broken) => {
          setLargeScreen(!broken);
          if (broken) {
            setCollapsed(true);
          } else {
            setCollapsed(isCollapsed);
          }
        }}
        style={{
          overflow: "auto",
          height: "100vh",
          backgroundColor: "rgb(0 21 40)",
        }}
      >
        <div className="logo text-white text-2xl pl-6 py-4 border-b border-slate-500">
          {project.logo.startsWith("http") || project.logo.startsWith("/") ? (
            <img
              src={!collapsed ? project.logo : project.icon}
              alt="logo"
              className="h-8"
            />
          ) : (
            <>{project.logo}</>
          )}
        </div>
        <Menu />
      </Sider>
      <AntLayout
        className="site-layout w-full"
        style={
          {
            "--sidebar-width": collapsed
              ? largeScreen
                ? "80px"
                : "0px"
              : "200px",
            "--content-area-width":
              "calc(100vw - (var(--sidebar-width) + 2rem))",
          } as any
        }
      >
        <Header
          style={{
            backgroundColor: "rgb(203 213 225)",
            paddingLeft: 16,
            paddingRight: 0,
            width: "100%",
          }}
        >
          <div className="w-full flex items-center justify-between">
            <span>
              {createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => {
                    localStorage.setItem("collapsed", !collapsed + "");
                    setCollapsed(!collapsed);
                  },
                },
              )}
            </span>
            <div className="flex space-x-4 items-center">
              <span>
                <BiNotification className=" text-xl " />
              </span>

              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <span
                          onClick={() => {
                            navigate("/changepassword");
                          }}
                        >
                          Şifre Değiştir
                        </span>
                      ),
                      key: "1",
                    },
                    {
                      type: "divider",
                    },
                    {
                      label: "Çıkış Yap",
                      key: "3",
                      onClick: logout,
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <div className="flex items-center space-x-2 cursor-pointer hover:bg-slate-200 px-4 transition-all">
                  <Avatar size={24} icon={<BiUser size={24} />} />
                  <p className="capitalize">{user?.name}</p>
                  <BiChevronDown />
                </div>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content
          className={`site-layout-background w-full overflow-y-scroll overflow-x-hidden p-4 pb-0 ${
            !collapsed && !largeScreen ? "" : ""
          }`}
        >
          {children}
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          {project.copyright}
        </Footer>
      </AntLayout>
    </div>
  );
};
