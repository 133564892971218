import { column } from "..";
import { CollectionController } from "./AbstractCollectionController";
import { ICollectionController } from "./ICollectionController";
import StandartAssetLayout from "../../Layouts/StandartAssetLayout";
import CreateReacordLayout from "../../Layouts/CreateRecordLayout";
import ImportLayout from "../../Layouts/ImportLayout";

export class StandartCollectionController
  extends CollectionController
  implements ICollectionController
{
  protected columns?: column[];
  protected form?: any;
  constructor(title: string, collection: string, options: any) {
    super(title, collection);
    this.list = (
      <StandartAssetLayout
        title={this.title}
        collection={this.collection}
        columns={options?.columns}
        customActions={options?.customActions}
        disableNewButton={options?.form ? true : false}
        search={options?.search}
      />
    );
    this.create = (
      <CreateReacordLayout
        title={this.title}
        collection={this.collection}
        layout={options?.form}
      />
    );
    if (options?.import) {
      this.import = (
        <ImportLayout
          collection={this.collection}
          structure={options?.import?.structure}
        />
      );
    }
  }
}
