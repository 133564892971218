import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Table, Pagination, Button, Input, Tooltip, Select } from "antd";

import { ImFilesEmpty } from "react-icons/im";

import { useQuery } from "@tanstack/react-query";
//import ExportFile from "./ExportFile";
import { fetchData } from "../../app/index";
import { ITableComponent, column } from "../../types";
import { DeleteAction, EditAction, DataViewAction } from "..";

import { ModalView } from "./ModalView";
import JSONTreeView from "./JSONTreeView";

const Option = Select.Option;

export const DataTable: ITableComponent = ({
  children,
  columns,
  collection,
  className,
  expandable,
  selection,
  editAction = true,
  deleteAction = true,
  actions = true,
  customActions = [],
}) => {
  //const exportEvent = typeof props.export === "undefined" ? true : props.export;

  const [searchParams, setSearchParams] = useSearchParams();
  // const [filters, setFilters] = React.useState(
  //   searchParams.get("filter")
  //     ? filterStringToFilters(searchParams.get("filter"))
  //     : [],
  // );
  const [queryStringParams, setQueryStringParams] = React.useState(
    searchParams.toString(),
  );
  const [filterString, setFilterstring] = React.useState("");

  const [pager, setPager] = React.useState({
    total: 1,
    page: parseInt(searchParams.get("page") as string) || 1,
    limit: parseInt(searchParams.get("limit") as string) || 50,
    count: 0,
  });

  // useEffect(() => {
  //   const filterQuery = filters.map((filter) => {
  //     return `${filter.key}:${filter.value}:${filter.op}`;
  //   });

  //   const filterString = filterQuery.join(";");
  //   if (filterString.length > 0) {
  //     //setSearchParams({ filter: `[${filterString}]` });
  //     searchParams.set("filter", `[${filterString}]`);
  //     setSearchParams(searchParams);
  //   } else {
  //     searchParams.delete("filter");
  //     setSearchParams(searchParams);
  //   }

  //   setFilterstring(filterString);
  //   setPage(1);
  // }, [filters, searchParams, setSearchParams]);

  useEffect(() => {
    setPager({
      ...pager,
      limit: parseInt(searchParams.get("limit") as string) || 50,
      page: parseInt(searchParams.get("page") as string) || 1,
    });
    setQueryStringParams(searchParams.toString());
  }, [searchParams]);

  const columnsWithActions: column[] = [
    ...columns,
    {
      title: "Actions",
      dataIndex: "_id",
      key: "_id",
      render: (_id: any, record) => (
        <div className="flex space-x-1">
          {typeof actions !== "boolean" && actions}
          {customActions.map((Action) => {
            return <Action record={record} />;
          })}
          <ModalView
            content={<JSONTreeView data={JSON.stringify(record)} />}
            Button={DataViewAction}
            //title={record._id}
          />
          {editAction && <EditAction id={_id} collection={collection} />}
          {deleteAction && <DeleteAction id={_id} collection={collection} />}
        </div>
      ),
    },
  ];

  // Queries
  const {
    isLoading,
    isError,
    data,
    error,
  }: { error: any; data: any; isError: any; isLoading: any } = useQuery(
    [collection, queryStringParams],
    async () => {
      const data = await fetchData(collection, queryStringParams);
      // console.log(data);
      setPager({
        total: Math.ceil(data.counter / data.limit),
        page: data.page,
        limit: data.limit,
        count: data.counter,
      });
      return [...data.data.map((i: any) => ({ ...i, key: i._id }))];
    },
  );

  return (
    <div
      className={`w-full h-full flex flex-col items-end space-y-4 ${className}`}
    >
      {/* <FilterGroup
        columns={props.columns}
        table={props.table}
        filters={filters}
        onSubmit={(val) => {
          const arr = [];
          Object.keys(val).forEach((key) => {
            if (val[key].isActive && val[key].value !== "") {
              arr.push({
                key,
                value: val[key].value,
                op: val[key].op,
              });
            }
          });
          setFilters(arr);
        }}
      /> */}
      {children}
      <Table
        className="w-full overflow-y-scroll"
        columns={actions !== false ? columnsWithActions : (columns as any)}
        dataSource={data}
        loading={isLoading}
        rowSelection={selection as any}
        {...expandable}
        locale={{
          emptyText: isError ? (
            <div className="my-16">
              <div className="text-center flex justify-center">
                <ImFilesEmpty style={{ fontSize: 48, marginBottom: 10 }} />
              </div>{" "}
              <br />
              <p>{error.response.data}</p>
            </div>
          ) : (
            <div className="my-16">
              <div className=" text-center flex justify-center">
                <ImFilesEmpty style={{ fontSize: 48, marginBottom: 10 }} />
              </div>{" "}
              <br />
              <p>Ürün Bulunamadı</p>
            </div>
          ),
        }}
        pagination={false}
      />
      {!isLoading && (
        <div className="w-full flex justify-between">
          {/* {exportEvent ? <ExportFile table={props.table} /> : <span></span>} */}
          <span></span>
          <Pagination
            showQuickJumper
            showSizeChanger
            defaultCurrent={1}
            total={pager.count}
            onChange={(n, s) => {
              searchParams.set("page", n.toString());
              searchParams.set("limit", s.toString());
              setSearchParams(searchParams);
            }}
            current={pager.page}
            pageSize={pager.limit}
          />
        </div>
      )}
    </div>
  );
};
