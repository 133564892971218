import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";
import moment from "moment";

const columns: column[] = [
  {
    title: "transfer Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/transfers/${record._id}/voyagers`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
  {
    title: "transfer Time",
    dataIndex: "date",
    key: "date",
    dataType: "number",
    render: (_id, record) => {
      return moment.unix(record.date).format("DD.MM.YYYY HH:mm");
    },
  },
  {
    title: "Summary",
    dataIndex: "_id",
    key: "_id",
    dataType: "string",
    render: (_id, record) => {
      return (
        <div className="flex">
          <div className="text-xs text-gray-500">
            {record.summary.completedVoyagers}
          </div>
          <div className="text-xs text-gray-500">/</div>
          <div className="text-xs text-gray-500">
            {record.summary.totalVoyagers}
          </div>
        </div>
      );
    },
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "field",
          fieldType: "array",
          name: "voyagers",
          label: "Voyagers",
          span: 1,
          item: {
            type: "field",
            name: "voyager",
            item: {
              type: "select",
              placeholder: "Select voyager",
              search: true,
              mode: "default",
              clear: false,
              sort: "desc",
            },
            remote: {
              url: `${process.env.REACT_APP_API_URL}/api/voyagers?search={{value}}`,
              fetchOnMount: true,
              value: "_id",
              label: "invoice",
            },
          },
        },
      ],
    },
  ],
};

export class TransferController extends StandartCollectionController {
  constructor() {
    super("Transfer", "transfers", {
      columns,
      form,
      search: "search",
    });
  }
}
