import { FC } from "react";
import { FiEdit } from "react-icons/fi";
import { TableActionButton } from "..";
import { useNavigate } from "react-router-dom";

export const EditAction: FC<any> = ({ id, collection }) => {
  const navigate = useNavigate();

  return (
    <TableActionButton
      popover={{
        content: "Edit Record",
        placement: "top",
      }}
      icon={<FiEdit />}
      onClick={() => {
        navigate(`/${collection}/${id}`);
      }}
      className="bg-amber-400 hover:bg-amber-300"
    />
  );
};
