import { Button, Input } from "antd";
import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import moment from "moment";

const WelcomeVoyagers = () => {
  const [data, setData] = React.useState<any>([]);
  const [searchString, setSearchString] = React.useState<string>("");

  const date = useParams<{ date: string }>().date;

  const importHandler = async (start: any, end: any) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/voyagers?hastransfer=true&date=${date}&limit=1000`,
      )
      .then((res) => {
        const transfer = res.data;
        setData(transfer.data);
      });
  };

  React.useEffect(() => {
    importHandler(null, null);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg text-bold bg-red-400 px-4 py-2 w-56">
          Voyager Welcome List
        </h3>
        <div className="w-64">
          <Input
            placeholder="Search"
            size="large"
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
        </div>
        <div className="flex space-x-4 text-sm">
          <span>
            onBoard {data.filter((i: any) => i.status !== "Pending").length} /{" "}
            {data.length}
          </span>
          <span>|</span>
          <span>
            inTransfer{" "}
            {data.filter((i: any) => i.status === "inTransfer").length} /{" "}
            {data.length}
          </span>
        </div>
      </div>

      {data
        .filter(
          (i: any) =>
            i.personalInformations.name.toLowerCase().indexOf(searchString) !==
              -1 || i.invoice.indexOf(searchString) !== -1
        )
        .map((voyager: any) => (
          <div
            className="grid grid-cols-12 w-full justify-between items-center border-b-2 border-b-blue-300 text-md even:bg-blue-100 py-3 px-4"
            key={voyager._id}
          >
            <span className=" col-span-1">{voyager.invoice}</span>
            <span className="col-span-2 text-center">
              {voyager.cruise.code}
            </span>
            <span className="col-span-4">
              {voyager.personalInformations.name}
            </span>
            <span className="col-span-1">
              {voyager.flightInformations.flightCarrier +
                voyager.flightInformations.flightNumber}
            </span>
            <span className="col-span-2">
              {formatTime(voyager.flightInformations.flightTime)}
            </span>
            <div className="flex space-x-2 col-span-2 justify-end">
              <Button
                className={`${
                  voyager.status !== "Pending" ? "bg-green-400" : "bg-slate-400"
                } text-white hover:bg-slate-100`}
                type="default"
                onClick={async () => {
                  const status = voyager.status;
                  setData(
                    data.map((item: any) => {
                      if (item._id === voyager._id) {
                        item.status = "Fetching";
                      }
                      return item;
                    })
                  );
                  await axios
                    .patch(
                      `${process.env.REACT_APP_API_URL}/api/voyagers/${voyager._id}`,
                      {
                        status: status === "Pending" ? "onBoard" : "Pending",
                      }
                    )
                    .then(() => {
                      setData(
                        data.map((item: any) => {
                          if (item._id === voyager._id) {
                            item.status =
                              status === "Pending" ? "onBoard" : "Pending";
                          }
                          return item;
                        })
                      );
                    });
                }}
                disabled={voyager.status === "Fetching"}
                loading={voyager.status === "Fetching"}
              >
                onBoard
              </Button>
              {/* <Button
                className={`${
                  voyager.status !== "Pending" && voyager.status !== "onBoard"
                    ? "bg-green-400"
                    : "bg-slate-400"
                } text-white hover:bg-slate-100`}
                type="default"
                onClick={async () => {
                  const status = voyager.status;
                  setData(
                    data.map((item: any) => {
                      if (item._id === voyager._id) {
                        item.status = "Fetching";
                      }
                      return item;
                    }),
                  );

                  await axios
                    .patch(
                      `https://its.uzmanapi.com/api/voyagers/${voyager._id}`,
                      {
                        status: status === "onBoard" ? "inTransfer" : "onBoard",
                      },
                    )
                    .then(() => {
                      setData(
                        data.map((item: any) => {
                          if (item._id === voyager._id) {
                            item.status =
                              status === "onBoard" ? "inTransfer" : "onBoard";
                          }
                          return item;
                        }),
                      );
                    });
                }}
                disabled={voyager.status === "Fetching"}
                loading={voyager.status === "Fetching"}
              >
                In Transfer
              </Button> */}
            </div>
          </div>
        ))}
    </div>
  );
};

export default WelcomeVoyagers;
