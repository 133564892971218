import { Col, Row, Space } from "antd";
import React from "react";
import { Input } from "./Input";
import { ObjectInput } from "./ObjectInput";
import { Button } from "./Button";
import { ArrayInput } from "./ArrayInput";
import { IField } from "../../../types/uiComponentTypes";

export interface FieldProps {
  name: string;
  type: string;
  span?: number;
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  onChange: (e: any) => void;
  value: string;
  item: any;
  rows?: number;
  id?: string;
  options?: any[];
}

export const Field: IField = ({
  name,
  label,
  style,
  error,
  width,
  height,
  children,
  required,
  className,
}) => {
  // let inputHandler = null;
  // if (type === "object") {
  //   inputHandler = <ObjectInput form={item} onChange={onChange} prop={name} />;
  // } else if (type === "array") {
  //   inputHandler = <ArrayInput form={item} onChange={onChange} prop={name} />;
  // } else {
  //   inputHandler = (
  //     <Input
  //       name={name}
  //       type={type}
  //       value={value}
  //       onChange={onChange}
  //       placeholder={placeholder}
  //       rows={rows || 4}
  //       options={options}
  //     />
  //   );
  // }

  return (
    <div
      className={className}
      id={name}
      style={{
        ...width,
        ...height,
        ...style,
      }}
    >
      <label className="text-xs text-gray-600 mb-1" htmlFor={name}>
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      {children}
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </div>
  );
};
