import React, { useState } from "react";
import "../../styles/JSONTreeView.css";
import { Typography } from "antd";
import { CopyableText } from "./Copyable";

const JSONTreeView: React.FC<any> = ({ data }) => {
  const jsonData = JSON.parse(data);

  return (
    <div className="json-tree-view p-8">
      <JSONNode data={jsonData} />
    </div>
  );
};

const JSONNode: React.FC<any> = ({ data, keyName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isObject = typeof data === "object" && data !== null;

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (isObject) {
      setIsOpen(!isOpen);
    }
  };

  const renderData = () => {
    if (Array.isArray(data)) {
      return data.map((item, index) => (
        <JSONNode key={index} data={item} keyName={index} />
      ));
    }

    if (isObject) {
      return Object.keys(data).map((key) => (
        <JSONNode key={key} data={data[key]} keyName={key} />
      ));
    }

    if (typeof data === "boolean") {
      return (
        <CopyableText className="text-blue-800 font-semibold">
          {data ? "true" : "false"}
        </CopyableText>
      );
    }

    return <CopyableText className="text-orange-700">{data}</CopyableText>;
  };

  return (
    <div className={`json-node ${isOpen ? "open" : ""}`}>
      {isObject ? (
        <span className="toggle-icon" onClick={handleClick}>
          {isOpen ? "▼" : "▶"}
        </span>
      ) : null}
      {keyName !== undefined && (
        <span className="key-name" onClick={handleClick}>
          {keyName}:
        </span>
      )}
      {isObject ? (
        isOpen ? (
          <div className="children">{renderData()}</div>
        ) : (
          <span onClick={handleClick}>
            {Array.isArray(data) ? "[...]" : "{...}"}
          </span>
        )
      ) : (
        renderData()
      )}
    </div>
  );
};

export default JSONTreeView;
