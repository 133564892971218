import { CopyableText } from "../../components";
import { BasicCollectionController, column } from "../../types";

const columns: column[] = [
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
    dataType: "string",
    render: (text: string) => <CopyableText>{text}</CopyableText>,
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "number",
  },
  {
    title: "Province",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (text: string) => text.capitalize(),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "grid",
      space: 2,
      items: [
        {
          type: "field",
          name: "code",
          label: "Code",
          span: 4,
          item: {
            type: "text",
            placeholder: "34",
          },
          validations: {
            required: true,
          },
        },
        {
          type: "field",
          name: "name",
          label: "Province",
          span: 8,
          item: {
            type: "text",
            placeholder: "İstanbul",
          },
          validations: {
            required: {
              message: "province is required",
            },
          },
        },
        {
          type: "button",
          inputType: "submit",
          value: "Submit",
          span: 12,
        },
      ],
    },
  ],
};

export class ProvinceController extends BasicCollectionController {
  constructor() {
    super("Province", "provinces", {
      columns,
      form: form,
    });
  }
}
