import React, { useState, useEffect, useCallback } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";

import { Button, Popconfirm, Progress, Select, Tooltip } from "antd";
import { Table, Spin, Input } from "antd";

import { GiConfirmed } from "react-icons/gi";
import { RiErrorWarningLine } from "react-icons/ri";
import { CgSandClock } from "react-icons/cg";

import { DatePicker, ImportFile } from "../components";
//import { SelectInput } from "../components/shared/components";

import type { FilterValue, SorterResult } from "antd/es/table/interface";
import axios from "axios";
import formatText from "../utils/textFormatter";
import excelColumnLetter from "../utils/excelColumnLetter";

const SelectInput = ({
  value,
  options,
  onChange,
}: {
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: any) => void;
}) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(!value);
  }, [value]);

  return (
    <Select
      value={value}
      style={{ width: "100%" }}
      onChange={(value) => {
        setHasError(!value);
        onChange(value);
        console.log(value);
      }}
      status={hasError ? "error" : undefined}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            option.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }
        return false;
      }}
    >
      {options.map((option, index) => (
        <Select.Option key={index} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const processData = (data: any, structure: any) => {
  const columnToKey: any = {};
  const input: any = {};
  const format: any = {};
  structure.forEach((item: any) => {
    columnToKey[item.column] = item.key;
    input[item.key] = item.input;
    format[item.key] = item.format;
  });

  const processedData = data.slice(1).map((row: any, index: number) => {
    const newRow: any = {};
    newRow.status = "waiting";

    // add unique id
    newRow.__key = Math.random().toString(36).substr(2, 9);

    row.forEach((cell: any, index: number) => {
      const columnLetter = excelColumnLetter(index + 1);
      if (columnToKey[columnLetter]) {
        if (format[columnToKey[columnLetter]]) {
          cell = formatText(format[columnToKey[columnLetter]], cell);
        }
        if (input[columnToKey[columnLetter]]) {
          if (input[columnToKey[columnLetter]].type === "select") {
            const option = input[columnToKey[columnLetter]].options?.find(
              (option: any) => {
                return (
                  option?.label?.toLowerCase() === cell?.toLowerCase() ||
                  option?.value?.toLowerCase() === cell?.toLowerCase()
                );
              },
            );
            if (option) {
              cell = option.value;
            } else {
              cell = undefined;
              newRow.status = "error";
            }
          }
        }
        newRow[columnToKey[columnLetter]] = cell;
      }
    });
    return newRow;
  });

  return processedData;
};

// let columnToStructure: any = {};
// columnToStructure = {};
// structure.forEach((item: any) => {
//   columnToStructure[item.key] = item;
// });

const ImportLayout: React.FC<any> = ({ structure: strc, collection }) => {
  const [structure, setStructure] = useState<any>([...strc]);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [success, setSuccess] = useState(0);
  const [error, setError] = useState(0);
  const [percent, setPercent] = useState<number>(0);
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const fetchSelectOptions = async () => {
    const newStructure = await Promise.all(
      structure.map(async (item: any) => {
        if (item.input && item.input.type === "select" && item.input.fetch) {
          try {
            const response = await axios.get(item.input.fetch);
            const data = response.data.data;

            // API'dan dönen veriyi opsiyonlar için uygun bir formata dönüştür
            const options = data.map((option: any) => ({
              value: option._id, // API'dan dönen değeri kullanın
              label: option.name ? option.name : option.code, // API'dan dönen etiketi kullanın
            }));

            return {
              ...item,
              input: {
                ...item.input,
                options,
              },
            };
          } catch (error) {
            console.error("Error fetching options:", error);
            return item;
          }
        } else {
          return item;
        }
      }),
    );

    setStructure(newStructure);
    setOptionsLoaded(true);
  };

  const onDrop = (acceptedFiles: any) => {
    if (!optionsLoaded) {
      alert("Lütfen seçeneklerin yüklenmesini bekleyin.");
      return;
    }
    setPercent(0);
    setSuccess(0);
    setError(0);
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (event: any) => {
        const fileData = event.target.result;
        let parsedData;

        if (file.name.endsWith(".csv")) {
          parsedData = Papa.parse(fileData, {
            header: false,
            dynamicTyping: true,
          }).data;
        } else {
          const workbook = XLSX.read(fileData, {
            type: rABS ? "binary" : "array",
          });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          parsedData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        }

        setData(processData(parsedData, structure));
        setCount(parsedData.length);
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  useEffect(() => {
    fetchSelectOptions();
  }, []);

  useEffect(() => {
    setError(data.filter((row: any) => row.status === "error").length);
  }, [data]);

  const uploadRow = async (row: any) => {
    //row status fetching
    setData((prevData: any) => {
      const updatedData: any = prevData.map((item: any) => {
        if (item.__key === row.__key) {
          return {
            ...item,
            status: "fetching",
          };
        }
        return item;
      });
      return updatedData;
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/${collection}`,
        row,
      );

      setPercent((prevPercent) => {
        const newPercent = prevPercent + 100 / count;
        if (newPercent >= Math.floor(100 - 100 / count)) {
          return 100;
        }
        return newPercent;
      });

      return {
        status:
          response.status === 200 || response.status === 201
            ? "success"
            : "error",
        message: `${response.data?.message}`,
      };
    } catch (error: any) {
      setPercent((prevPercent) => {
        const newPercent = prevPercent + 100 / count;
        if (newPercent >= Math.floor(100 - 100 / count)) {
          return 100;
        }
        return newPercent;
      });
      return {
        status: "error",
        message: `${error?.response?.data?.message}`,
      };
    }
  };

  const handleUploadButtonClick = async () => {
    setCount(data.length);
    let successCount = 0;
    let errorCount = 0;

    data.forEach(async (row: any, index: number) => {
      const result = await uploadRow(row);

      if (result?.status === "success") {
        successCount++;
      } else {
        errorCount++;
      }

      setData((prevData: any) => {
        const updatedData: any = prevData.map((item: any) => {
          if (item.__key === row.__key) {
            return {
              ...item,
              status: result?.status === "success" ? "success" : "error",
              message: result?.message,
            };
          }
          return item;
        });
        // updatedData[index] = {
        //   ...row,
        //   status: result?.status === "success" ? "success" : "error",
        //   message: result?.message,
        // };
        return updatedData;
      });

      setSuccess(successCount);
      setError(errorCount);
    });
  };

  const handleRetryButtonClick = async (row: any) => {
    setData((prevData: any) => {
      const data = prevData.filter((i: any) => i.status === "error");
      setCount(data.length);
      setError(0);
      setSuccess(0);
      setPercent(0);
      return data.map((i: any) => ({ ...i, status: "waiting" }));
    });
  };

  const handleCellChange = (
    rowIndex: number,
    dataIndex: number,
    value: any,
  ) => {
    console.log(rowIndex, dataIndex, value);
    setData((prevData: any) => {
      const updatedData = prevData.map((row: any, index: number) => {
        if (row.__key === rowIndex) {
          return { ...row, [dataIndex]: value };
        }
        return row;
      });
      return updatedData;
    });
  };

  const handleDelete = (rowIndex: any) => {
    setData((prevData: any) => {
      console.log(rowIndex);
      const updatedData = prevData.filter(
        (_: any, index: number) => _.__key !== rowIndex,
      );
      setCount(count - 1);
      return updatedData;
    });
  };

  const getColumnConfig = () => {
    const columns = [
      {
        key: "__key",
        title: "__key",
        dataIndex: "__key",
        width: 100,
        render: (text: string, record: any, index: number) => (
          <p key={record.__key}>{record.__key}</p>
        ),
        fixed: "left",
      },

      ...structure.map((item: any, i: number) => ({
        key: item.key,
        title: item.key,
        dataIndex: item.key,
        editable: true,
        width: item.width || 200,
        render: item.render,
      })),
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        width: 100,
        filters: [
          { text: "Error", value: "error" },
          { text: "Waiting", value: "waiting" },
          { text: "Success", value: "success" },
          { text: "Fetching", value: "fetching" },
        ],
        //filteredValue: filteredInfo.name || null,
        onFilter: (value: string, record: any) => record.status === value,
        render: (text: string, record: any, index: number) => {
          if (record.status === "success") {
            return (
              <span
                className="flex items-center text-green-500 space-x-1"
                key={index}
              >
                <GiConfirmed />
                <span>Success</span>
              </span>
            );
          } else if (record.status === "error") {
            return (
              <Tooltip title={record.message}>
                <span
                  className="flex items-center text-red-500 space-x-1"
                  key={index}
                >
                  <RiErrorWarningLine />
                  <span>Error</span>
                </span>
              </Tooltip>
            );
          } else if (record.status === "fetching") {
            return (
              <span
                className="flex items-center text-blue-500 space-x-1"
                key={index}
              >
                <Spin size="small" />
                <span>fetching</span>
              </span>
            );
          } else {
            return (
              <span
                className="flex items-center text-yellow-500 space-x-1"
                key={index}
              >
                <CgSandClock />
                <span>waiting</span>
              </span>
            );
          }
        },
        fixed: "right",
      },
      {
        key: "operation",
        title: "",
        dataIndex: "operation",
        render: (_: any, record: any, index: number) => (
          <Popconfirm
            title="Bu satırı silmek istediğinize emin misiniz?"
            onConfirm={() => handleDelete(record.__key)}
            key={index}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        ),
        width: 100,
        fixed: "right",
      },
    ];

    return columns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any, rowIndex: number) => ({
          record,
          inputType:
            structure.find((i: any) => i.key === col.dataIndex)?.input?.type ||
            "text",
          options:
            structure.find((i: any) => i.key === col.dataIndex)?.input
              ?.options || [],
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record.status === "waiting" || record.status === "error",
          index: record.__key,
          handleCellChange,
        }),
      };
    });
  };

  return (
    <div className="w-full h-full flex flex-col space-y-4">
      <div className="w-full items-center flex justify-between">
        <div className="flex items-center justify-end space-x-4">
          <Progress
            type="circle"
            percent={Math.round(percent)}
            style={{ marginRight: 8 }}
          />
          <div className="flex flex-col space-y-1">
            <span className="bg-blue-400 text-white pl-2 pr-4 w-32 py-1">
              data : {count}
            </span>
            <span className="bg-green-400 text-white pl-2 pr-4 w-32 py-1">
              success : {success}
            </span>
            <span className="bg-red-400 text-white pl-2 pr-4 w-32 py-1">
              error : {error}
            </span>
          </div>
        </div>
        <ImportFile
          onDrop={onDrop}
          accept={{
            "text/csv": [".csv"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
            "application/vnd.ms-excel.sheet.binary.macroenabled.12": [".xlsb"],
          }}
          className="w-96 h-24"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button
          type="primary"
          onClick={handleRetryButtonClick}
          className="w-48"
          danger
          disabled={error === 0 || Math.round(percent) !== 100}
        >
          Edit Errored {error} Lines
        </Button>
        <Button
          type="primary"
          onClick={handleUploadButtonClick}
          className="w-48"
        >
          Upload Data
        </Button>
      </div>

      <div className="flex-grow w-full">
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
          columns={getColumnConfig()}
          rowClassName="editable-row"
          rowKey={"__key"}
          size="small"
          scroll={{ x: 400 }}
          pagination={{ pageSize: 25 }}
          style={{
            maxWidth: "var(--content-area-width)",
          }}
        />
      </div>
    </div>
  );
};

export default ImportLayout;

const EditableCell: React.FC<any> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  handleCellChange,
  inputType,
  options,
  ...restProps
}) => {
  //const inputType = columnToStructure[dataIndex]?.input?.type;
  //const options = columnToStructure[dataIndex]?.input?.options;

  const handleCellInputChange = useCallback(
    (e: any) => {
      handleCellChange(index, dataIndex, e.target.value);
    },
    [index, dataIndex],
  );

  const handleSelectChange = useCallback(
    (value: any) => {
      console.log(value);
      handleCellChange(index, dataIndex, value);
    },
    [index, dataIndex],
  );

  const renderInput = () => {
    if (inputType === "select") {
      return (
        <SelectInput
          value={record[dataIndex]}
          options={options}
          onChange={handleSelectChange}
        />
      );
    } else if (inputType === "date") {
      return (
        <DatePicker
          value={record[dataIndex]}
          onChange={handleCellInputChange}
        />
      );
    } else if (inputType === "readonly") {
      return <Input value={record[dataIndex]} disabled />;
    } else {
      return (
        <Input value={record[dataIndex]} onChange={handleCellInputChange} />
      );
    }
  };

  return <td {...restProps}>{editing ? renderInput() : children}</td>;
};
