import React, { useEffect } from "react";
import { Layout } from "../components";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";

import Home from "../pages/";

import {
  Provinces,
  Hotels,
  Ports,
  Airports,
  Routes as RouteCt,
  Restaurants,
  Products,
  CarFirms,
  CarTypes,
  Clients,
  PersonalFirms,
  Personals,
  Museums,
  GuideFees,
  Cicerones,
  Packages,
  Voyagers,
  Users,
  Cruises,
  Transfers,
  Operations,
} from "./controllers";

import JSONTreeView from "../components/shared/JSONTreeView";
import Transfer from "../pages/transfer";
import CreateTransfer from "../pages/createTransfer";
import { EditTransfers } from "../pages/editTransfer";
import TransferVoyagers from "../pages/tranfervoyagers";
import PageLogin from "../pages/PageLogin";
import { useAuth } from "../helpers/AuthContext";
import { Spin } from "antd";
import PagePassChange from "../pages/PagePassChange";
import WelcomeVoyagers from "../pages/voyagerWelcomeList";

function App() {
  const location = useLocation();

  // useEffect(() => {
  //   document.getElementById("loader")?.remove();
  // }, []);

  const { loggedIn, loading, user, logout } = useAuth();
  let navigate = useNavigate();

  if (location.pathname === "/login" && loggedIn) {
    return <Navigate to="/" />;
  }

  if (!loggedIn && location.pathname !== "/login" && !loading) {
    return <Navigate to="/login" />;
  }

  if (!loading) {
    document.getElementById("loader")?.remove();
  }

  switch (location.pathname) {
    case "/login":
      return <PageLogin />;
    default:
      return (
        <Layout>
          <Routes>
            {/* Home Page */}
            <Route path="/" element={Operations.list} />
            <Route path="/transfers" element={Transfers.list} />
            <Route
              path="/transfers/:id/voyagers"
              element={<TransferVoyagers />}
            />
            <Route path="/welcomeList/:date" element={<WelcomeVoyagers />} />

            {user?.role === "Admin" && (
              <>
                <Route path="/transfers/create" element={<CreateTransfer />} />
                <Route path="/transfers/:id" element={<EditTransfers />} />

                {/* Provinces Router */}
                <Route path="/provinces" element={Provinces.list} />
                <Route path="/ports" element={Ports.list} />
                <Route path="/airports" element={Airports.list} />
                <Route path="/routes" element={RouteCt.list} />

                {/* Hotels Router */}
                <Route path="/hotels" element={Hotels.list} />
                <Route path="/hotels/create" element={Hotels.create} />
                <Route path="/hotels/:id" element={Hotels.create} />

                {/* Restaurants Router */}
                <Route path="/restaurants" element={Restaurants.list} />
                <Route
                  path="/restaurants/create"
                  element={Restaurants.create}
                />
                <Route path="/restaurants/:id" element={Restaurants.create} />

                {/* Products Router */}
                <Route path="/products" element={Products.list} />
                <Route path="/products/create" element={Products.create} />
                <Route path="/products/:id" element={Products.create} />

                {/* Cars Router */}
                <Route path="/carfirms" element={CarFirms.list} />
                <Route path="/carfirms/create" element={CarFirms.create} />
                <Route path="/carfirms/:id" element={CarFirms.create} />
                <Route path="/cartypes" element={CarTypes.list} />

                {/* Clients Router */}
                <Route path="/clients" element={Clients.list} />
                <Route path="/clients/create" element={Clients.create} />
                <Route path="/clients/:id" element={Clients.create} />
                <Route path="/cruises" element={Cruises.list} />

                {/* Personal Firms Router */}
                <Route path="/personalfirms" element={PersonalFirms.list} />
                <Route
                  path="/personalfirms/create"
                  element={PersonalFirms.create}
                />
                <Route
                  path="/personalfirms/:id"
                  element={PersonalFirms.create}
                />

                {/* Personals Router */}
                <Route path="/personals" element={Personals.list} />
                <Route path="/personals/create" element={Personals.create} />
                <Route path="/personals/:id" element={Personals.create} />

                {/* Museums Router */}
                <Route path="/museums" element={Museums.list} />
                <Route path="/museums/create" element={Museums.create} />
                <Route path="/museums/:id" element={Museums.create} />

                {/* Guide Fees Router */}
                <Route path="/guidefees" element={GuideFees.list} />
                <Route path="/guidefees/create" element={GuideFees.create} />
                <Route path="/guidefees/:id" element={GuideFees.create} />

                {/* Cicerones Router */}
                <Route path="/guides" element={Cicerones.list} />
                <Route path="/guides/create" element={Cicerones.create} />
                <Route path="/guides/:id" element={Cicerones.create} />

                {/* Packages Router */}
                <Route path="/packages" element={Packages.list} />
                <Route path="/packages/create" element={Packages.create} />
                <Route path="/packages/:id" element={Packages.create} />

                {/* Voyagers Router */}
                <Route path="/voyagers" element={Voyagers.list} />
                <Route path="/voyagers/create" element={Voyagers.create} />
                <Route path="/voyagers/import" element={Voyagers.import} />
                <Route path="/voyagers/:id" element={Voyagers.create} />

                {/* Users Router */}
                <Route path="/users" element={Users.list} />

                <Route
                  path="test"
                  element={
                    <JSONTreeView
                      data={JSON.stringify({
                        key1: "value1",
                        key2: ["item1", "item2", { nestedKey: "nestedValue" }],
                      })}
                    />
                  }
                />
              </>
            )}
            {/* 404 */}
            <Route
              path="/changepassword"
              element={<PagePassChange user={user} />}
            />
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </Layout>
      );
  }
}

export default App;
