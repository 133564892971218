import React, { useState, useEffect } from "react";
import { PageGenerator } from "../../../app/uiFunctions/PageGenerator";
import cn from "classnames";
import classGenerator from "../../../app/uiFunctions/classGenerator";
import { useFormik } from "formik";
import { Button } from "antd";
import { getFormData } from "../../../app/uiFunctions/getFormData";
import { AiOutlineMinusCircle } from "react-icons/ai";

function arrayToObject(array: any) {
  const result: any = {};

  array?.forEach((item: any) => {
    result[item._id] = item;
  });

  return result;
}

export const ArrayInput: React.FC<any> = ({
  onChange,
  form,
  context,
  value,
}) => {
  const [data, setData] = useState<any>(arrayToObject(value) || {});

  const formik: any = useFormik({
    initialValues: data,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const { resetForm } = formik;

  const handleAddInput = () => {
    const _id = Math.random().toString(36).substr(2, 9);
    const fieldName = `${_id}`;
    formik.setFieldValue(fieldName, "");
  };

  const handleRemoveInput = (key: string) => {
    formik.setFieldValue(key, undefined);
  };

  useEffect(() => {
    if (!value) {
      setData({});
    } else {
      setData(arrayToObject(value));
    }
  }, [value]);

  useEffect(() => {
    if (Object.keys(formik.values).length === 0) {
      resetForm();
    }
    onChange({
      target: {
        name: form.name,
        value: Object.keys(formik.values)?.map((key) => formik.values[key]),
      },
    });
  }, [formik.values]);

  return (
    <div className="w-full p-2 flex flex-col space-y-2 border">
      {Object.keys(formik.values).map((key) => (
        <div
          key={key}
          className="w-full flex space-x-2 justify-between items-end"
        >
          <div className="w-full flex-grid">
            <SubComponent
              item={form.item}
              name={key}
              onChange={formik.handleChange}
              context={context}
              formik={formik}
            />
          </div>
          <span
            onClick={() => handleRemoveInput(key)}
            className={cn("text-red-600 cursor-pointer text-xl", {
              "pb-3": context.inputSize === "large",
              "pb-1.5": context.inputSize === undefined,
              "pb-0.5": context.inputSize === "small",
            })}
          >
            <AiOutlineMinusCircle />
          </span>
        </div>
      ))}
      <Button
        type="dashed"
        onClick={handleAddInput}
        block
        size={context.inputSize || "middle"}
      >
        Add
      </Button>
    </div>
  );
};

const SubComponent: React.FC<any> = ({ item, context, name, formik }) => {
  return PageGenerator(
    {
      ...item,
      name,
    },
    {
      ...context,
      formik,
    },
  );
};
