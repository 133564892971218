export const validate = (values: any, validations: any) => {
  const errors: any = {};
  Object.keys(validations).forEach((key) => {
    const validation = validations[key];
    if (validation?.required && values[key] === 0) {
      return;
    }
    if (validation?.required && !values[key]) {
      errors[key] = validation.required.message || `${key} is required`;
    }
    if (validation?.required && (values[key] === "" || values[key] === null)) {
      errors[key] = validation.required.message || `${key} is required`;
    }
    if (validation?.min && values[key].length < validation?.min.value) {
      errors[key] =
        validation.min.message ||
        `${key} length must be greater than ${validation.min.value}`;
    }
    if (validation?.max && values[key]?.length > validation?.max.value) {
      errors[key] =
        validation.max.message ||
        `${key} length must be less than ${validation.max.value}`;
    }
    if (validation?.mustNumber && isNaN(values[key])) {
      errors[key] = validation.mustNumber.message || `${key} must be a number`;
    }
    if (validation?.mustString && !isNaN(values[key])) {
      errors[key] = validation.mustString.message || `${key} must be a string`;
    }
    if (validation?.minNumber && values[key] < validation?.minNumber.value) {
      errors[key] =
        validation.minNumber.message ||
        `${key} must be greater than ${validation.minNumber.value}`;
    }
    if (validation?.maxNumber && values[key] > validation?.maxNumber.value) {
      errors[key] =
        validation.maxNumber.message ||
        `${key} must be less than ${validation.maxNumber.value}`;
    }
    if (
      validation?.pattern &&
      !validation?.pattern.value.test(values[key]) &&
      values[key] !== undefined
    ) {
      errors[key] = validation.pattern.message || `${key} is invalid`;
    }
  });
  return errors;
};
