import axios from "axios";

const endPoint = process.env.REACT_APP_API_URL || "";

export const fetchData = async (collection: any, params: string) => {
  const { data } = await axios.get(`${endPoint}/api/${collection}?${params}`);
  return data;
};

export const deleteRecord = async (collection: any, id: string) => {
  const { data } = await axios.delete(`${endPoint}/api/${collection}/${id}`);
  return data;
};
