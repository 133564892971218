import { Breadcrumb } from "antd";
import { IBreadcrumbComponent } from "../../types";

export const BreadCrumb: IBreadcrumbComponent = ({ breadcrumb }) => (
  <Breadcrumb>
    {breadcrumb.map((item, index) =>
      item.link ? (
        <Breadcrumb.Item key={index} href={item.link}>
          {item.title}
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
      ),
    )}
  </Breadcrumb>
);
