import { BasicCollectionController, column } from "../../types";

const columns: column[] = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "number",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
  },
  {
    title: "Seet",
    dataIndex: "seet",
    key: "seet",
    dataType: "number",
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "grid",
      space: 2,
      items: [
        {
          type: "field",
          name: "code",
          label: "Code",
          span: 3,
          item: {
            type: "text",
            placeholder: "code",
            format: {
              onChange: "trim",
            },
          },
          validations: {
            required: true,
          },
        },
        {
          type: "field",
          name: "name",
          label: "Name",
          span: 6,
          item: {
            type: "text",
            placeholder: "name",
            format: {
              onChange: "removeExtraSpaces",
              onsubmit: "trim",
            },
          },
          validations: {
            required: true,
          },
        },
        {
          type: "field",
          name: "seet",
          label: "Seet",
          span: 3,
          item: {
            type: "number",
            placeholder: "6",
            limitations: {
              min: 1,
            },
          },
        },
        {
          type: "button",
          inputType: "submit",
          value: "Kaydet",
          span: 12,
        },
      ],
    },
  ],
};

export class CarTypeController extends BasicCollectionController {
  constructor() {
    super("Type", "cartypes", {
      columns,
      form,
    });
  }
}
