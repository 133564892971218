import { Menu as AntMenu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import { FaRoute, FaShip, FaPlaneDeparture } from "react-icons/fa";
import {
  MdLocationOn,
  MdOutlineFoodBank,
  MdPersonAddAlt,
  MdOutlinePeopleAlt,
  MdOutlineMuseum,
  MdBackpack,
} from "react-icons/md";
import { BiMapPin, BiNetworkChart } from "react-icons/bi";
import { RiHotelLine, RiShipLine } from "react-icons/ri";
import { AiOutlineCar, AiOutlineIdcard } from "react-icons/ai";
import { IoLanguageOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { TbDatabaseImport } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import { useAuth } from "../../helpers/AuthContext";

export const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  return (
    <AntMenu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[location.pathname]}
      items={
        user?.role === "Admin"
          ? [
              {
                label: "Home",
                key: "/home",
                icon: <RiHotelLine />,
                onClick: () => {
                  navigate("/");
                },
              },
              {
                label: "Transfers",
                key: "transfers",
                icon: <IoIosPeople />,
                children: [
                  {
                    label: "Transfers",
                    key: "/transfers",
                    icon: <RiHotelLine />,
                    onClick: () => {
                      navigate("/transfers?sort=date,time&desc");
                    },
                  },
                  {
                    label: "Create Transfer",
                    key: "/transfers/create",
                    icon: <RiHotelLine />,
                    onClick: () => {
                      navigate("/transfers/create");
                    },
                  },
                  {
                    label: "Voyagers Welcome List",
                    key: "/welcomeList",
                    icon: <RiHotelLine />,
                    onClick: () => {
                      const date = moment().format("YYYY-MM-DD");
                      navigate(`/welcomeList/${date}`);
                    },
                  },
                ],
              },

              {
                label: "Voyagers",
                key: "voyagers",
                icon: <IoIosPeople />,
                children: [
                  {
                    label: "List",
                    key: "/voyagers",
                    icon: <HiOutlineClipboardDocumentList />,
                    onClick: () => {
                      navigate("/voyagers");
                    },
                  },
                  {
                    label: "Import",
                    key: "/voyagers/import",
                    icon: <TbDatabaseImport />,
                    onClick: () => {
                      navigate("/voyagers/import");
                    },
                  },
                ],
              },
              {
                label: "Packages",
                key: "/packages",
                icon: <MdBackpack />,
                onClick: () => {
                  navigate("/packages");
                },
              },
              {
                label: "Guides",
                key: "cicero",
                icon: <MdPersonAddAlt />,
                children: [
                  {
                    label: "Guides",
                    key: "/guides",
                    icon: <MdPersonAddAlt />,
                    onClick: () => {
                      navigate("/guides");
                    },
                  },
                  {
                    label: "Fees",
                    key: "/guidefees",
                    icon: <IoLanguageOutline />,
                    onClick: () => {
                      navigate("/guidefees");
                    },
                  },
                ],
              },
              {
                label: "Vehicles",
                key: "cars",
                icon: <AiOutlineCar />,
                children: [
                  {
                    label: "Firms",
                    key: "/carfirms",
                    icon: <AiOutlineIdcard />,
                    onClick: () => {
                      navigate("/carfirms");
                    },
                  },
                  {
                    label: "Types",
                    key: "/cartypes",
                    icon: <AiOutlineCar />,
                    onClick: () => {
                      navigate("/cartypes");
                    },
                  },
                ],
              },
              {
                label: "Personals",
                key: "persona",
                icon: <AiOutlineIdcard />,
                children: [
                  {
                    label: "Firms",
                    key: "/personalfirms",
                    icon: <BiNetworkChart />,
                    onClick: () => {
                      navigate("/personalfirms");
                    },
                  },
                  {
                    label: "Personals",
                    key: "/personals",
                    icon: <MdOutlinePeopleAlt />,
                    onClick: () => {
                      navigate("/personals");
                    },
                  },
                ],
              },
              {
                label: "Clients",
                key: "client",
                icon: <RiShipLine />,
                children: [
                  {
                    label: "Clients",
                    key: "/clients",
                    icon: <BiNetworkChart />,
                    onClick: () => {
                      navigate("/clients");
                    },
                  },
                  {
                    label: "Cruises",
                    key: "/cruises",
                    icon: <RiShipLine />,
                    onClick: () => {
                      navigate("/cruises");
                    },
                  },
                ],
              },

              {
                label: "Hotels",
                key: "/hotels",
                icon: <RiHotelLine />,
                onClick: () => {
                  navigate("/hotels");
                },
              },
              {
                label: "Restaurants",
                key: "/restaurants",
                icon: <MdOutlineFoodBank />,
                onClick: () => {
                  //navigate("/restaurants");
                  alert("Coming soon");
                },
              },
              {
                label: "Museums",
                key: "/museums",
                icon: <MdOutlineMuseum />,
                onClick: () => {
                  //navigate("/museums");
                  alert("Coming soon");
                },
              },
              {
                label: "Regions",
                key: "sells",
                icon: <MdLocationOn />,
                children: [
                  {
                    label: "Ports",
                    key: "/ports",
                    icon: <FaShip />,
                    onClick: () => {
                      navigate("/ports");
                    },
                  },
                  {
                    label: "Airports",
                    key: "/airports",
                    icon: <FaPlaneDeparture />,
                    onClick: () => {
                      navigate("/airports");
                    },
                  },
                  {
                    label: "Provinces",
                    key: "/provinces",
                    icon: <BiMapPin />,
                    onClick: () => {
                      navigate("/provinces?page=1&limit=20&sort=code");
                    },
                  },
                ],
              },
              {
                label: "Users",
                key: "/users",
                icon: <FaUser />,
                onClick: () => {
                  navigate("/users");
                },
              },
            ]
          : [
              {
                label: "Home",
                key: "/home",
                icon: <RiHotelLine />,
                onClick: () => {
                  navigate("/");
                },
              },
              {
                label: "Transfers",
                key: "transfers",
                icon: <IoIosPeople />,
                children: [
                  {
                    label: "Transfers",
                    key: "/transfers",
                    icon: <RiHotelLine />,
                    onClick: () => {
                      navigate("/transfers?sort=date,time&desc");
                    },
                  },
                  {
                    label: "Voyagers Welcome List",
                    key: "/welcomeList",
                    icon: <RiHotelLine />,
                    onClick: () => {
                      const date = moment().format("YYYY-MM-DD");
                      navigate(`/welcomeList/${date}`);
                    },
                  },
                ],
              },
            ]
      }
    />
  );
};
