import { ICollectionController } from "./ICollectionController";

export abstract class CollectionController implements ICollectionController {
  protected collection: string;
  protected title: string;
  list?: React.ReactElement;
  create?: React.ReactElement;
  edit?: React.ReactElement;
  import?: React.ReactElement;

  constructor(title: string, collection: string) {
    this.collection = collection;
    this.title = title;
  }
}
