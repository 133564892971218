import React, { useState, useEffect } from "react";
import { DatePicker, TimePicker as TmPicker } from "antd";
import { ITimePicker } from "../../../types/uiComponentTypes";
import cn from "classnames";
import axios from "axios";
import { accessNestedObject } from "../../../utils/accessNestedObjects";

const PickerWithType = ({
  type,
  onChange,
  className,
  range,
}: {
  type: any;
  onChange: any;
  className: any;
  range?: boolean;
}) => {
  if (type === "time")
    return <TmPicker className={className} onChange={onChange} />;
  if (type === "date")
    return <DatePicker className={className} onChange={onChange} />;
  return <DatePicker className={className} picker={type} onChange={onChange} />;
};

export const TimePicker: ITimePicker = ({
  name,
  value,
  onChange,
  mode = "date",
  size,
}) => {
  return (
    <PickerWithType
      type={mode}
      className={cn("w-full block")}
      onChange={(e: any) => {
        console.log(e);
        onChange({
          target: {
            name,
            value: e.$d,
          },
        });
      }}
    />
  );
};
