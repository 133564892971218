import React from "react";
import { Table, PageLayout, CreateForm } from "../components";
import { PageGenerator } from "../app/uiFunctions/PageGenerator";

const BasicAssetLayout: React.FC<any> = ({
  collection,
  title,
  columns,
  form,
}) => {
  return (
    <PageLayout
      title={title}
      breadcrumb={[{ title: "App" }, { title: title, link: `/${collection}` }]}
    >
      <div className="flex h-full space-x-4 items-start flex-wrap">
        <div className="flex-grow order-2">
          <Table collection={collection} columns={columns} editAction={false} />
        </div>
        <div className="w-full flex-grow overflow-y-scroll md:w-1/3 order-1 md:order-12 bg-white p-4">
          {PageGenerator(form, { collection })}
        </div>
      </div>
    </PageLayout>
  );
};

export default BasicAssetLayout;
