import React, { useState, useEffect } from "react";
import { Checkbox as CHbox, Row, Col } from "antd";
import { ICheckbox } from "../../../types/uiComponentTypes/form/ICheckbox";
import cn from "classnames";
import axios from "axios";
import { accessNestedObject } from "../../../utils/accessNestedObjects";

export const Checkbox: ICheckbox = ({
  name,
  value,
  onChange,
  option,
  options = [],
  cols = 1,
  remote = false,
}) => {
  const [data, setData] = useState<any>(options);

  const fetch = (callback: any) => {
    axios
      .get(remote?.url || "", {
        headers: {
          Authorization: remote?.token
            ? `Bearer ${localStorage.getItem(remote.token)}`
            : "",
        },
      })
      .then((response: any) => {
        callback(
          accessNestedObject(response.data, remote?.data || "data").map(
            (i: any) => {
              return {
                label: accessNestedObject(i, remote?.label || "name"),
                value: accessNestedObject(i, remote?.value || "_id"),
              };
            },
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (val: string) => {
    if (remote !== false) {
      fetch(setData);
    }
  };

  useEffect(() => {
    if (remote !== false) {
      handleSearch("");
    }
  }, []);

  if (option) {
    return (
      <CHbox
        onChange={(e) => {
          onChange({ target: { name, value: e.target.checked } });
        }}
        checked={Boolean(value)}
        className={cn("w-full")}
      >
        {option}
      </CHbox>
    );
  } else {
    return (
      <CHbox.Group
        className={cn("w-full block")}
        onChange={(val) => {
          onChange({ target: { name, value: val } });
        }}
        value={value as any}
      >
        <Row>
          {data.map((item: any) => (
            <Col span={24 / cols} key={item.value}>
              <CHbox value={item.value}>{item.label}</CHbox>
            </Col>
          ))}
        </Row>
      </CHbox.Group>
    );
  }
};
