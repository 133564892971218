import React from "react";
import { Switch as Swt } from "antd";
import { ISwitch } from "../../../types/uiComponentTypes";

export const Switch: ISwitch = ({ name, value, onChange, size }) => (
  <div className="w-full">
    <Swt
      size={size === "small" ? "small" : "default"}
      className="bg-slate-300"
      checked={Boolean(value || false)}
      onChange={(val) => {
        onChange({
          target: {
            name,
            value: val,
          },
        });
      }}
    />
  </div>
);
