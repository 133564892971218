import { Link } from "react-router-dom";
import { column, StandartCollectionController } from "../../types";
import formatText from "../../utils/textFormatter";

const columns: column[] = [
  {
    title: "Client Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
    render: (_id, record) => (
      <Link to={`/clients/${record._id}`} className="font-semibold">
        {record.name}
      </Link>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    dataType: "string",
    render: (_id, record) => (
      <a href={`tel:${record.phone}`}>
        {formatText("phoneNumberWithCountryCode", record.phone)}
      </a>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    dataType: "string",
    render: (_id, record) => (
      <a href={`mailto:${record.email}`}>{record.email}</a>
    ),
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 3,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Client Name",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "client name",
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                  validations: {
                    required: {
                      message: "Client name is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "address",
                  label: "Client Address",
                  span: 12,
                  item: {
                    type: "textarea",
                    placeholder: "client address",
                    rows: 2,
                    format: {
                      onChange: "removeExtraSpaces",
                    },
                  },
                  validations: {
                    required: {
                      message: "Client address is required",
                    },
                  },
                },
                {
                  type: "field",
                  name: "phone",
                  label: "Phone",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "phone",
                    format: {
                      onChange: "phoneNumberWithCountryCode",
                      onSubmit: "dbPhoneNumber",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^\+\d{1,3} \d{3,14}$/,
                      message: "Telefon numarası geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  name: "email",
                  label: "E-mail",
                  span: 6,
                  item: {
                    type: "text",
                    placeholder: "email",
                    format: {
                      onChange: "trim",
                    },
                  },
                  validations: {
                    pattern: {
                      value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                      message: "E-posta adresi geçersiz",
                    },
                  },
                },
                {
                  type: "field",
                  fieldType: "array",
                  name: "contacts",
                  label: "Contacts",
                  span: 12,
                  item: {
                    type: "field",
                    name: "contactinfo",
                    fieldType: "object",
                    compact: true,
                    span: 12,
                    items: [
                      {
                        type: "field",
                        name: "name",
                        label: "Name",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "name",
                          format: {
                            onChange: "removeExtraSpaces",
                            onSubmit: "trim",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "phone",
                        label: "Phone",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "phone",
                          format: {
                            onChange: "phoneNumberWithCountryCode",
                            onSubmit: "dbPhoneNumber",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^\+\d{1,3} \d{3,14}$/,
                            message: "Telefon numarası geçersiz",
                          },
                        },
                      },
                      {
                        type: "field",
                        name: "email",
                        label: "E-mail",
                        span: 4,
                        item: {
                          type: "text",
                          placeholder: "email",
                          format: {
                            onChange: "trim",
                          },
                        },
                        validations: {
                          pattern: {
                            value: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
                            message: "E-posta adresi geçersiz",
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class ClientController extends StandartCollectionController {
  constructor() {
    super("Client", "clients", {
      columns,
      form,
    });
  }
}
