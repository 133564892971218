export default function excelColumnLetter(columnNumber: number): string {
  let columnName = "";

  while (columnNumber > 0) {
    const remainder = (columnNumber - 1) % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    columnNumber = Math.floor((columnNumber - remainder) / 26);
  }

  return columnName;
}
