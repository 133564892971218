import { column, StandartCollectionController } from "../../types";

const columns: column[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    dataType: "string",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    dataType: "string",
  },
];

const form: any = {
  type: "form",
  requestType: "create",
  items: [
    {
      type: "xStack",
      breakpoint: "md",
      gap: 4,
      align: "start",
      justify: "between",
      items: [
        {
          type: "yStack",
          gap: 4,
          height: "100%",
          span: 1,
          items: [
            {
              type: "grid",
              cols: 12,
              gap: 2,
              items: [
                {
                  type: "field",
                  name: "name",
                  label: "Res İsmi",
                  span: 12,
                  item: {
                    type: "text",
                    placeholder: "hotel name",
                  },
                  validations: {
                    required: true,
                  },
                },
                {
                  type: "field",
                  name: "code",
                  label: "Code",
                  span: 12,
                  item: {
                    type: "number",
                    placeholder: "0",
                    format: {
                      step: 0.1,
                    },
                    limitations: {
                      min: 0,
                      max: 10000,
                    },
                  },
                  validations: {
                    required: true,
                  },
                },
                {
                  type: "field",
                  name: "price",
                  label: "Price",
                  span: 12,
                  item: {
                    type: "number",
                    placeholder: "0",
                    format: {
                      step: 0.1,
                    },
                    limitations: {
                      min: 0,
                      max: 10000,
                    },
                  },
                  validations: {
                    required: true,
                  },
                },
                {
                  type: "button",
                  inputType: "submit",
                  value: "Kaydet",
                  span: 12,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export class ProductController extends StandartCollectionController {
  constructor() {
    super("Products", "products", {
      columns,
      form,
    });
  }
}
