import { FC } from "react";
import { IoNewspaperOutline } from "react-icons/io5";
import { TableActionButton } from "..";

export const DataViewAction: FC<any> = ({ onClick }) => {
  return (
    <TableActionButton
      popover={{
        content: "View Record",
        placement: "top",
      }}
      icon={<IoNewspaperOutline className="text-white" />}
      onClick={onClick}
      className="bg-blue-500 hover:bg-blue-400"
    />
  );
};
